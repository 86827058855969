import React from 'react';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';
import Slider from "react-slick";
import { ImageURL } from './Images';
import { Banner } from '../sytled-components/Banner';
import { NavLink } from 'react-router-dom';
import FixedMenu from '../components/FixedMenu';

const Partners = (props) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        <i className="fas fa-arrow-right customarrows right"></i>
      </div>
    );
  }

  const randomColor = require('randomcolor');

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        <i className="fas fa-arrow-left customarrows left"></i>
      </div>
    );
  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }]
  };

  return (


    <div className="partnersdiv">
      <Container>
        {props.title && <h2>{props.title}</h2>}
        {props.content &&
          <Slider {...settings} className="partnerslider">
            {props.content.slice(0, 8).map((cardobj, partnerindex) => {
              return (
                <div className="wrapperpartner">
                  <Card className="partnercard mt-3 mb-3" >

                    <NavLink to={`testimonial/${cardobj.read_more_link}`}>
                      {cardobj.patient_image != 'common_avatar' && <div class="hover13 hover15">
                        <figure>
                          <Card.Img variant="top" src={ImageURL(cardobj.image)} className="blur" />
                          <Card.ImgOverlay>
                            <img className="patient_image" src={ImageURL(cardobj.patient_image)} style={{width:"120px", height: "120px"}} />
                          </Card.ImgOverlay>
                        </figure>
                      </div>
                      }

                      {cardobj.patient_image == 'common_avatar' && <div class="hover13 hover15">
                        <figure>
                          <Card.Img variant="top" src={ImageURL(cardobj.image)} />
                        </figure>
                      </div>
                      }
                    </NavLink>

                    <Card.Body>
                      <div className="circleavatarobj" style={{ marginTop: "-20px" }}>

                        <div className="avatar_desc" style={{ width: "100%", textAlign: "center" }}>
                          <h5>{cardobj.name}</h5>
                          <p>{cardobj.occupation}</p>
                        </div>
                      </div>
                      <Card.Text className="max-lines">
                        {cardobj.testimonial}
                      </Card.Text>
                    </Card.Body>
                    <div className="readmore" style={{ position: "relative", margin: "10px" }}>
                      <NavLink to={`testimonial/${cardobj.read_more_link}`}>{cardobj.read_more}</NavLink>
                    </div>
                  </Card>
                </div>
              )
            })}
          </Slider>
        }
        <div className="viewmore">
          <NavLink to={props.view_more_link}>{props.view_more}</NavLink>
        </div>
      </Container>
    </div>
  )
}
export default Partners;
