import React,{Fragment,useState,useEffect} from 'react';
import FooterPageComponent from '../components/FooterPageComponent';
const GDPR=()=>{
	const [GDPRData,setGDPRData]=useState(null);
	const isDev=process.env.REACT_APP_ENV=='isDev';
	const isProd=process.env.REACT_APP_ENV=='isProd';
var home_path="http://localhost:3000/";
	if(isDev){
  home_path=process.env.REACT_APP_PATH_DEV_FILEPATH;
}else if(isProd){
  home_path=process.env.REACT_APP_PATH_PROD_FILEPATH;
}
	const updateContent=()=>{
		fetch(home_path+'gdpr.json',{ headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    		}
			})
		.then((resp)=>{
			return resp.ok?resp.json():null})
		.then((response)=>{
			setGDPRData((response&&response.length>0)?response[0]:null);
			setTimeout(()=>{
				window.scrollTo({top:0, behavior: 'smooth'})
			});
		})
	}
	useEffect(()=>{
		updateContent();
	},[])
return(
	<Fragment>
	{GDPRData?
	<FooterPageComponent title={GDPRData.content.title} content={GDPRData}/>:null
	}
	</Fragment>
	)
}
export default GDPR;