import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Navbar, Nav, NavDropdown, ButtonGroup, Button, ListGroup, Container, Row, Col } from 'react-bootstrap';
import Landing from '../components/Landing';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import { NavLink, useHistory } from 'react-router-dom';
import FixedMenu from '../components/FixedMenu';
import { InformationBox, SocialIcons, ShareIcons } from '../components/HelperComponents';
import Select from 'react-select';
import { ReactSelectStyles } from '../components/reactselect';
import MetaTags from 'react-meta-tags';
import { renderBodyContent } from '../utils/extrafunctions';
import PageLoader from '../components/PageLoader';
function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}
const ConditionalTreatment = (props) => {

	const history = useHistory();
	console.log("history", process.env);
	const { title, allInfo, url, subfolder } = props;
	const [links, setlinks] = useState(null);
	const [activelink, setactivelink] = useState(null);
	const [loader, setloader] = useState(true);
	const [conditionalContent, setconditionalContent] = useState(null);
	const isDev = process.env.REACT_APP_ENV == 'isDev';
	const isProd = process.env.REACT_APP_ENV == 'isProd';
	var conditional_path = `http://localhost:3000/conditional/`;
	if (isDev) {
		conditional_path = process.env.REACT_APP_PATH_DEV_FILEPATH + process.env.REACT_APP_PATH_CONDITIONAL;
	} else if (isProd) {
		conditional_path = process.env.REACT_APP_PATH_PROD_FILEPATH + process.env.REACT_APP_PATH_CONDITIONAL;
	}
	const previous = usePrevious(url);
	useEffect(() => {
		if (previous != url) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			if (allInfo) {
				var quicklinksdata = [];
				allInfo.map((obj) => {
					obj.content.map((obj1) => {
						quicklinksdata.push({ label: obj1.title, value: "/" + obj1.url });
					})
				})
				setactivelink(quicklinksdata.find((obj) => obj.value == history.location.pathname))
				setlinks(quicklinksdata)
			}
			updateContent(url);
		}
	})
	const updateContent = (url) => {
		// alert(url);
		setloader(true);
		fetch(conditional_path + subfolder + "/" + url + '.json', {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then((resp) => {
				return resp.ok ? resp.json() : null
			})
			.then((response) => {
				setconditionalContent((response && response.length > 0) ? response[0] : null);
				function addeventlistner(e) {
					e.preventDefault();
					var offsettop = document.querySelector(this.getAttribute('href')).offsetTop;
					console.log(this.getAttribute('href'));
					window.scrollTo({
						top: offsettop - (window.innerWidth > 991 ? 140 : 200),
						behavior: "smooth"
					});
					// document.querySelector(this.getAttribute('href')).scrollIntoView({
					// 	top:100,
					//     behavior: 'smooth'
					// });

				}
				document.querySelectorAll('.quicklink a[href^="#"]').forEach(anchor => {
					anchor.addEventListener('click', addeventlistner);
					return () => {
						anchor.removeEventListener('click', addeventlistner)
					}
				});
				setloader(null);
			})
	}
	const renderContent = (content) => {
		// console.log('activelink',history.location.pathname)
		return (
			<div className="quicklink">
				<h2>Quick Link</h2>
				{content.content.quickLinks.map((obj, index) => {
					return (
						<a href={`#content${index + 1}`} key={`links${index + 1}`}>{obj.title}</a>
					)
				})}
			</div>
		)
	}
	const content = conditionalContent;
	return (
		<Landing footerbg={"#ffffff"} active={'conditional'}>
			<MetaTags>
				<title>{content && content.pageTitle}</title>

				{content && content.meta && content.meta.length > 0 && content.meta.map((m, i) => {
					return (
						<meta name={m.name} content={m.content}/>
					)
				})}

			</MetaTags>
			<div className="virtualconsulation_div conditional_treatment_div">
				<FixedMenu direction={'right'} />
				{loader && <PageLoader absolute={true} linear={true} />}
				{content &&
					<Fragment>
						<Banner className="bannerimage" bannerurl={ImageURL(content.content.bannerURL)} >
							<div className="banner-text">
								<h3>{content.pageTitle}</h3>
							</div>
						</Banner>
						<section className="info_sharebox">
							<Row className="space-between pl-3 pr-3 inforow ml-0 mr-0">
								<Col lg={6} md={12} className="pb-3">
									<a target="_blank" href={content.content.pdflink} download>
										<div className="flexrow">

											<Images.pdf_download />

											<a target="_blank" href={content.content.pdflink}>
												<Images.pdf_print />
											</a>
											<p className="pdftitle">{content.content.pdfTitle}</p>
										</div>

									</a>
								</Col>
								<Col lg={6} md={12} className="conditional_social_icons">
									<div className="flexrow shareimage flex-wrap pb-3 flexend">
										<div className="flexrow flex-wrap">
											<span className="share">Share</span>
											<ShareIcons link={window.location.href} override />
										</div>
									</div>
								</Col>
							</Row>
						</section>
					</Fragment>
				}
				<section className="middle_section">
					<Row className="ml-0 mr-0">
						<Col lg={3} md={12}>
							<div className="mobilediv">
								<h2>{title}</h2>
								<Select onChange={(data) => history.push(data.value)} options={links} value={activelink} />
							</div>
							<ul className="all_info">
								{allInfo.map((obj) => {
									return obj.content.map((obj1) => {
										return (
											<NavLink to={obj1.url}>{obj1.title}</NavLink>
										)
									})
								})}
							</ul>
						</Col>
						<Col lg={9} className="middle_div_col" >
							{content ? renderContent(content) : <div className="pl-3 pr-3 nocontnentred">No Content</div>}

							<section className="bodysection">
								{content && content.content && content.content.quickLinks.map((obj, index) => {
									return (
										<div id={`content${index + 1}`} className="quicklink_section">
											{Object.keys(obj).map((obj1) => {
												return renderBodyContent(obj[obj1], obj1)
											})}
										</div>
									)
								})

								}
							</section>
						</Col>
						{/* <Col className="right_content text-center" lg={3} >
							{content && content.content.rightImages &&
								<Row className="ml-0 mr-0">
									{content.content.rightImages.map((images, index) => {
										return (
											<Col lg={12} md={4} xs={12} className="rightbannercol">
												<Banner className="conditional_right_banner" bannerurl={ImageURL(images)} />
											</Col>
										)
									})}
								</Row>
							}
						</Col> */}
					</Row>
				</section>
				{/* <section className="bodysection">
					{content && content.content && content.content.quickLinks.map((obj, index) => {
						return (
							<div id={`content${index + 1}`} className="quicklink_section">
								{Object.keys(obj).map((obj1) => {
									return renderBodyContent(obj[obj1], obj1)
								})}
							</div>
						)
					})

					}
				</section> */}
			</div>
		</Landing>
	)
}
export default ConditionalTreatment;
