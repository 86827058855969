import styled from '@emotion/styled'

export const CurvedLayout = styled.div `
        background-color: ${props=>props.bgcolor};
        padding:${props=>props.direction&&'10px'};
        border-top-left-radius:${props=>props.direction?props.direction.left:"10px"};
        border-top-right-radius:${props=>props.direction&&props.direction.right};
        box-shadow:${props=>props.shadow&&'0px 1px 15px #0000001A'};
        `;
export const CurvedButton = styled.button `
        background-color: ${props=>props.bgcolor};
        border-top-left-radius:10px;
        outline:0;
        padding:0;
        `;
export const CurvedInput = styled.input `
background-color: ${props=>props.bgcolor};
border-top-left-radius: ${props=>!props.right?'10px':'0px'};
border-top-right-radius:${props=>props.right?'10px':'0px'};
outline:0;
padding:0;
`;
export const CurvedTextArea = styled.textarea `
background-color: ${props=>props.bgcolor};
border-top-left-radius: ${props=>!props.right?'10px':'0px'};
border-top-right-radius:${props=>props.right?'10px':'0px'};
outline:0;
padding:0;
`;
