import React, { Fragment, useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, ButtonGroup, Button, ListGroup, Container, Row, Col } from 'react-bootstrap';
import Landing from './Landing';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from './Images';
import { NavLink, useHistory } from 'react-router-dom';
import FixedMenu from './FixedMenu';
import { InformationBox, SocialIcons, ShareIcons } from './HelperComponents';
import Select from 'react-select';
import { ReactSelectStyles } from './reactselect';
import MetaTags from 'react-meta-tags';
import { renderBodyContent } from '../utils/extrafunctions';
const TestimonialDetailComponent = (props) => {
	const history = useHistory();
	const [contentData, setcontentData] = useState(null);
	console.log("props", props);
	const content = contentData ? contentData : props.content;
	return (
		<Landing footerbg={"#ffffff"} active={props.path}>
			<MetaTags>
				<title>{props && props.title}</title>
				<meta name="keyword" content="Karthik Karuppaiah testimonial by Patient" />
				<meta name="description" content="Karthik Karuppaiah testimonial by Patient" />
			</MetaTags>
			<div className="virtualconsulation_div conditional_treatment_div">
				<FixedMenu direction={'right'} />

				<Fragment>
					<Banner className="bannerimage" bannerurl={ImageURL("testimonial")} >
						<div class="banner-text">
							<h3>
								<span style={{ cursor: "pointer" }}>
									<a className="fas fa-arrow-circle-left fa-6"
										onClick={history.goBack}>
									</a>
								</span>
								<span style={{ paddingLeft: "30px" }}>Karuppaiah Testimonial</span>
							</h3>
						</div>
					</Banner>
				</Fragment>

				<section className="bodysection">
					<div className="quicklink_section">
						<div className="avatarimage" style={{borderBottom:"0px"}}>
							<Row>
								<Col md={3} lg={3} style={{width:"300px", overflow:"hidden", padding:"10px"}}>
									{props.patientDetail.patient_image != 'common_avatar' && <div style={{overflow:"hide"}}>
										<img src={ImageURL(props.patientDetail.patient_image)}></img>
									</div>
									}
									{props.patientDetail.patient_image == 'common_avatar' && <div>										
										<img src={ImageURL(props.patientDetail.image)}></img>
									</div>
									}
								</Col>
								<Col  md={8} lg={8} style={{padding:"10px"}, {marginLeft:"20px"}} >	
									<p className="avt_h1">{props.patientDetail.name}</p>
									<p className="avt_h2">{props.patientDetail.occupation}</p>
									<hr style={{margin:"5px 0px"}}/>
									<p className="para">{props.patientDetail.testimonial}</p>
								</Col>
							</Row>
						</div>
						
					</div>

				</section>
			</div>
		</Landing>
	)
}
export default TestimonialDetailComponent;
