import React, { Fragment, useState, useEffect } from 'react';
import { Carousel, Row, Col, Container, Modal, Tabs, Tab, Card, Image, Pagination, InputGroup, FormControl } from 'react-bootstrap';
import Landing from '../components/Landing';
import ConsultForm from '../components/Forms/consultform';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import { InformationBox, SocialIcons } from '../components/HelperComponents';
import FixedMenu from '../components/FixedMenu';
import { ReactSVG } from 'react-svg';
import MetaTags from 'react-meta-tags';
import PageLoader from '../components/PageLoader';
import FooterPageComponent from '../components/FooterPageComponent';
import { useHistory, HashRouter, Route, Switch, BrowserRouter as Router, Redirect, useLocation, NavLink } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
const PersonalCard = (obj, bloghomepath) => {
  return (
    <Card className="personalCard">
      <Row className="peronalrow mr-0 ml-0">
        <Col lg={5} md={5} xs={5} className="pl-0 pr-0">
          <Banner className="polygonimage" bannerurl={ImageURL(obj.image)} />
        </Col>
        <Col className="pl-0 pr-0">
          <Card.Body className="pt-1 pl-2">
            <Card.Text>
              <h5>{obj.title}</h5>{obj.description}
            </Card.Text>
            <Row className="readmorerow ml-0 mr-0">
              <Col className="pl-0 pr-0">
                {obj.showview &&
                  <div className="svgicon">
                    <ReactSVG src={`${ImageURL('eye')}`} />
                  </div>
                }
                {obj.showcomments &&
                  <div className="svgicon">
                    <img alt="noimage" src={`${ImageURL('message')}`} />
                  </div>
                }
              </Col>
              <Col className="text-right pl-0 pr-0">
                <NavLink to={`${bloghomepath}/${obj.read_more_link}`}>{obj.read_more}</NavLink>
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  )
}
const renderpagination = (obj, active_number, setactive) => {
  let active = active_number;
  let items = [];
  for (let number = 1; number <= obj; number++) {
    items.push(
      <Pagination.Item onClick={() => setactive(number)} key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <Pagination>
      {items}
      <div className="next_page">
        <Pagination.Next onClick={() => active_number == obj ? setactive(1) : setactive(active_number + 1)} />
      </div>
    </Pagination>
  )
}
const Blog = (props) => {
  const [blogcontent, setblogcontent] = useState(null);
  const [allpost, setallposts] = useState(null);
  const [cloneposts, setcloneposts] = useState(null);
  const [limit, setlimit] = useState(6);
  const [active, setactive] = useState(1);
  const [searchtext, setSearchText] = useState("");
  const isDev = process.env.REACT_APP_ENV == 'isDev';
  const isProd = process.env.REACT_APP_ENV == 'isProd';
  var home_path = "http://localhost:3000/";
  if (isDev) {
    home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
  } else if (isProd) {
    home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
  }
  const updateContent = () => {
    fetch(home_path + 'blog.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((resp) => {
        console.log(resp);
        return resp.ok ? resp.json() : null
      })
      .then((response) => {
        console.log("homejson", response);
        setblogcontent((response && response.length > 0) ? response[0] : null);
        setallposts((response && response.length > 0) ? response[0].content.posts : null)
        setcloneposts((response && response.length > 0) ? response[0].content.posts : null)
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        });
      })
  }
  useEffect(() => {
    updateContent();
  }, [])
  const cloneData = (data) => {
    return JSON.parse(JSON.stringify(data));
  }
  const filterPosts = (data) => {
    if (data == "") {
      setallposts(cloneposts)
    } else {
      const filterData = cloneposts.filter((obj) => obj.title.includes(data));
      setallposts(filterData);
    }
  }
  //   useEffect(()=>{
  //     setTimeout(()=>{
  // window.scrollTo({top:0, behavior: 'smooth'})
  //     },200)
  // },[])

  const totalpage = Math.ceil(allpost ? (allpost.length / limit) : 0);
  const splicedData = allpost && JSON.parse(JSON.stringify(allpost)).splice((active == 1 ? active - 1 : (((active - 1) * limit))), limit);
  return (
    <Fragment>
      <Route exact path={`${props.match.path}/`}>
        <Landing footerbg={"#ffffff"} active={'blog'}>
          <MetaTags>
            <title>{blogcontent && blogcontent.pageTitle} </title>

            {blogcontent && blogcontent.meta && blogcontent.meta.length > 0 && blogcontent.meta.map((m, i) => {
              return (
                <meta name={m.name} content={m.content} />
              )
            })}
          </MetaTags>
          {!blogcontent && <PageLoader />}
          {blogcontent &&
            <div className="virtualconsulation_div testimonal_div blog_div">
              <FixedMenu />
              <Banner className="bannerimage" bannerurl={ImageURL(blogcontent.content.banner)} >
                <div class="banner-text">
                  <h3>{blogcontent.content.title}</h3>
                </div>
              </Banner>
              <Container className="pt-5 pb-5">
                <Row >
                  <Col lg={9} md={12} className="order2">
                    <div className="web_blog_posts">
                      <Row>
                        {splicedData && splicedData.length == 0 && <p>No Records Found...</p>}
                        {splicedData && splicedData.sort((b, a) => a.createdAt - b.createdAt).map((obj, index) => {
                          return (
                            <Col className="width100 pb-4" lg={6} md={12} key={"blogarr_index" + index}>
                              {PersonalCard(obj, props.match.url)}
                            </Col>
                          )
                        })
                        }
                      </Row>
                      {totalpage > 0 && renderpagination(totalpage, active, setactive)}
                    </div>
                  </Col>
                  <Col lg={3} md={12} className="order1">
                    <div className="highlightdiv">
                      <header>Search</header>
                      <InputGroup className="mb-3">
                        <FormControl
                          onChange={(e) => setSearchText(e.target.value)}
                          placeholder="Search"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                        <InputGroup.Append className="pointerclass" onClick={() => filterPosts(searchtext)}>
                          <InputGroup.Text id="basic-addon2">Search</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      <Fragment>
                        <div className="mobile_blog_posts">
                          <Row >
                            {splicedData && splicedData.length == 0 && <p>No Records Found...</p>}
                            {splicedData && splicedData.map((obj, index) => {
                              return (
                                <Col className="width100 pb-4" lg={6} md={12} key={"blogarr_index" + index}>
                                  {PersonalCard(obj, props.match.url)}
                                </Col>
                              )
                            })
                            }
                          </Row>
                          {totalpage > 0 && renderpagination(totalpage, active, setactive)}
                        </div>
                      </Fragment>
                    </div>
                    {blogcontent.showcomments &&
                      <div className="highlightdiv">
                        <header>Recent Comments</header>
                      </div>
                    }
                    <div className="highlightdiv">
                      <header>Recent Posts</header>
                      <ul>
                        {cloneposts && cloneData(cloneposts).sort((b, a) => a.createdAt - b.createdAt).splice(0, 6).map((obj) => {
                          return (
                            <li><NavLink to={`${props.match.path}/${obj.read_more_link}`}><i className="fas fa-arrow-right"></i> <span>{obj.title}</span></NavLink></li>
                          )
                        })}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>

            </div>
          }

        </Landing>
      </Route>
      {allpost && allpost.map((obj) => {
        return (
          <Route exact path={`${props.match.path}/${obj.read_more_link}`}><FooterPageComponent path="blogs" title="Blog" loadjson={`${obj.read_more_link}`} /></Route>
        )
      })}
    </Fragment>
  )
}
export default Blog;