import React, { useState, useEffect } from 'react';
import Images, { ImageURL } from './Images';
import { Banner } from '../sytled-components/Banner';
import AppointmentForm from './Forms/AppointmentForm';
import { Modal } from 'react-bootstrap';
const FixedMenu = (props) => {
	const [show, setshow] = useState(false);
	const [showclass, setshowclass] = useState(null);
	const onWindowResize = () => {
		console.log("windowscroll");
		if (props.scrolleffect && document.querySelector("." + props.detectclass)) {
			if (window.scrollY >= document.querySelector("." + props.detectclass).offsetTop) {
				setshowclass(true);
			} else {
				setshowclass(null);
			}
			// setIsMobile(true);
		}
	}
	useEffect(() => {
		window.addEventListener('scroll', onWindowResize);

		return () => window.removeEventListener('scroll', onWindowResize);

	}, [onWindowResize])
	return (
		<div id="fixedmenu" className={`fixedmenu  ${props.scrolleffect ? (showclass ? 'show' : 'hide') : ''} ${props.bottom && 'bottom'} ${show ? 'hide' : 'show'}`}>

			<div className={`menu_fixed  ${props.direction}`}>
				<div className="menubox" onClick={() => setshow(true)}>
					<Banner className="bannermenu" bannerurl={ImageURL('calendar')} />
					<p>Book Now</p>
				</div>
				<div className="menubox">
					<a href="https://www.iwantgreatcare.org/doctors/mr-karthik-karuppaiah" target="_blank">
						<Banner className="bannermenu secondary" bannerurl={ImageURL('certificate')} />
					</a>
				</div>
				<div className="menubox">
					<a href="https://lips.org.uk/consultants/mr-karthik-karuppaiah/" target="_blank">
						<Banner className="bannermenu secondary" bannerurl={ImageURL('/images/common/lips.png')} />
					</a>
				</div>
				<div className="menubox">
					<a href="http://londonbjc.com/" target="_blank">
						<Banner className="bannermenu secondary" bannerurl={ImageURL('bonejoint')} />
					</a>
				</div>
			</div>
			<Modal className="appointmentFormDiv" show={show} onHide={() => setshow(false)} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Book an appointment</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AppointmentForm />
				</Modal.Body>
			</Modal>
		</div>
	)
}
export default FixedMenu;