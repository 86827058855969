import React, { Fragment, useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, ButtonGroup, Button, ListGroup } from 'react-bootstrap'
import logo from '../images/logo.png';
import Images, { ImageURL } from './Images';
import MenuChildren from './MenuChildren';
import MenuChildrenMobile from './MenuChildrenMobile';
import { SocialIcons } from './HelperComponents';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import menuitems from '../data/menudata.json';
import { Link, NavLink } from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;
const Header = (props) => {
  const [show, setshow] = useState(false);
  const [tabnav, settabnav] = useState(0);
  const [drawerstate, setdrawerstate] = useState(false);
  const [menuitems, setmenuitems] = useState(null);
  const [resizeWidth, setresizeWidth] = useState(getWidth());
  const setactiveclass = (data) => {
    window.localStorage['active'] = data;
    // return window.location.href.includes(data);
  }
  const isDev = process.env.REACT_APP_ENV == 'isDev';
  const isProd = process.env.REACT_APP_ENV == 'isProd';
  var home_path = "http://localhost:3000/";
  if (isDev) {
    home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
  } else if (isProd) {
    home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
  }
  const updateContent = () => {
    fetch(home_path + 'menudata.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((resp) => {
        console.log(resp);
        return resp.ok ? resp.json() : null
      })
      .then((response) => {
        // console.log("mediajson",response);
        setmenuitems((response) ? response : null);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        });
        // settestimonalData((response&&response.length>0)?response[0].content.testimonals[0].content:null)

      })
  }
  useEffect(() => {
    updateContent();
    const resizeListener = () => {
      // change width from the state object
      setresizeWidth(getWidth())
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
    // resizeListener();
    // window.scrollTo({top:0, behavior: 'smooth'})
  }, [])
  const renderactiveclasses = (data, compare) => {
    if (data == compare) {
      return 'activeblue'
    } else {
      return '';
    }
    // if(data==window.localStorage['active']){
    //   return 'activenavlink';
    // }else{
    //   return "";
    // }
  }
  return (
    <Fragment>
      {menuitems &&
        <Fragment>
          
        <Navbar className="customNavBar" collapseOnSelect expand="lg" sticky="top">

        <div>
        <div style={{float:"left"}}>
          <Link to={"/"}>
            <Navbar.Brand style={{ width: 100 }}>
              <img
                src={ImageURL('Logo')}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
              
            </Navbar.Brand>
          </Link>
         </div>

         <div class="d-block d-sm-block d-md-block d-lg-none" style={{ float: "left", margin: "10px 20px 10px 10px" }}>
                <span style={{ fontSize: "12px", color: "#000" }}>

                <a href="tel:+44 (0)20 8194 8555" style={{color:"#03a9f4"}} >
                  <span class="customimg-mobile-center">
                    <img src={ImageURL("smartphoneBlue")} alt="smartphone" />
                  </span>
                  +44 (0)20 8194 8555
                  </a>  
                  
                  <br />
                

                <a href="mailto:karthik.medsec@gmail.com" style={{color:"#03a9f4"}}>
                  <span class="customimg-mobile-center">
                    <img src={ImageURL("mailBlue")} alt="mail" />
                  </span>
                  karthik.medsec@gmail.com 
                 </a>

                  <br />
                  
                 <a href="mailto:karuppaiah.admin@lips.org.uk" style={{color:"#03a9f4"}}>
                     <span class="customimg-mobile-center">
                    <img src={ImageURL("mailBlue")} alt="mail" />
                  </span>
                  karuppaiah.admin@lips.org.uk
                  </a>
                </span>
          </div>
             


          </div>
          <div className="header_body">
            <div className="header_top">
              <h2 style={{marginLeft:"5px"}}>LONDON UPPER LIMB SURGERY</h2>

                <div class="headerContactContainer d-none d-xl-block">
                    <a href="tel:+44 (0)20 8194 8555" >
                      <div class="iconbox removejustify">
                        <span class="customimg2">
                          <img src={ImageURL("smartphone")} alt="smartphone" />
                          </span>
                          <span style={{fontSize:"12px", color:"#fff"}}>+44 (0)20 8194 8555</span>
                        </div>
                      </a>
                      <a href="mailto:karthik.medsec@gmail.com;karuppaiah.admin@lips.org.uk">
                        <div class="iconbox removejustify">
                          <span class="customimg2">
                            <img src={ImageURL("mail")} alt="mail" />
                            </span>
                            <span style={{fontSize:"12px", color:"#fff"}}>karthik.medsec@gmail.com <br/> karuppaiah.admin@lips.org.uk</span>
                          </div>
                        </a>
                      
                      </div>
             
              <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setdrawerstate(true)} />
              {resizeWidth>991?<div className="socialIcons">
                <SocialIcons />
              </div>:null}
            </div>
            <div className="header_bottom">
              <Navbar.Collapse id="responsive-navbar-nav1">
                <Nav className={`mr-auto`}>
                  <ButtonGroup>
                    <NavLink className={renderactiveclasses(props.active, 'home')} onClick={() => setactiveclass('home')} to="/">Home <i className="fas fa-chevron-down"></i></NavLink>
                  </ButtonGroup>
                  <ButtonGroup onMouseEnter={(e) => setshow('mypractise')} onMouseLeave={(e) => setshow(false)}>
                    <Link className={`icon ${show == 'mypractise' && 'activenav'}`} >My Practice<i className="fas fa-chevron-down"></i></Link>
                    {isBrowser && show == 'mypractise' &&
                      <MenuChildren tabclassname={'blacktab'} data={menuitems[show]} show={show} />
                    }
                  </ButtonGroup>
                  {/* old version changed - 24-03-2022
                  <ButtonGroup onMouseEnter={(e) => setshow('conditional')} onMouseLeave={(e) => setshow(false)} >
                    <Link className={`icon ${show == 'conditional' && 'activenav'} ${renderactiveclasses(props.active, 'conditional')}`} >Conditions & Treatment<i className="fas fa-chevron-down"></i></Link>
                    {isBrowser && show == 'conditional' &&
                      <MenuChildren onhide={()=>setshow(false)} tabclassname={'blacktab'} data={menuitems[show]} show={show} />
                    }
                  </ButtonGroup>*/}
                  
                    <Link to="/conditions-and-treatment" className={`icon ${show == 'conditional' && 'activenav'} ${renderactiveclasses(props.active, 'conditional')}`} >Conditions & Treatment</Link>
                    
                  <ButtonGroup onMouseEnter={(e) => setshow('patientinfo')} onMouseLeave={(e) => setshow(false)}>
                    <Link className={`icon ${show == 'patientinfo' && 'activenav'} ${renderactiveclasses(props.active, 'patient')}`} >Patient Information<i className="fas fa-chevron-down"></i></Link>
                    {isBrowser && show == 'patientinfo' &&
                      <MenuChildren onhide={()=>setshow(false)} tabclassname={'blacktab'} data={menuitems[show]} show={show} />
                    }
                  </ButtonGroup>
                  {/*<NavLink className={`listitem ${renderactiveclasses(props.active, 'virtual')}`} to="/virtual-consultation">Virtual Consulatation <i className="fas fa-chevron-down"></i></NavLink>*/}
                  <NavLink className={`listitem ${renderactiveclasses(props.active, 'blog')} ${renderactiveclasses(props.active, 'blogs')}`} to="/blog">Blog <i className="fas fa-chevron-down"></i></NavLink>
                  <NavLink className={`listitem ${renderactiveclasses(props.active, 'testimoinal')} ${renderactiveclasses(props.active, 'testimonials')}`} to="/testimonial">Testimonials<i className="fas fa-chevron-down"></i></NavLink>
                  <NavLink className={`listitem ${renderactiveclasses(props.active,'media')}`} to="/media">Media <i className="fas fa-chevron-down"></i></NavLink> 
                  <NavLink className={`listitem ${renderactiveclasses(props.active, 'contact')}`} to="/contact-us">Contact Us <i className="fas fa-chevron-down"></i></NavLink>
                </Nav>

              </Navbar.Collapse>

            </div>
          </div>

          <SwipeableDrawer
            anchor={"right"}
            open={drawerstate}
            onClose={() => setdrawerstate(false)}
            onOpen={() => setdrawerstate(true)}
          >
            <div className={`mobilemoreDiv animatedtransition left ${tabnav == 0 && 'toggleanimateclass'}`}>
              <Link className="listitem" to="/">Home</Link>
              <ButtonGroup>
                <Link className="listitem" onClick={() => settabnav('mypractise')}>My Practice <i className="fas fa-chevron-down"></i></Link>
              </ButtonGroup>
              {/*<Link className="listitem" onClick={() => settabnav('conditional')}>Conditions & Treatment <i className="fas fa-chevron-down"></i></Link>*/}
              <Link className="listitem" onClick={() => settabnav('patientinfo')}>Patient Information <i className="fas fa-chevron-down"></i></Link>
              <Link className="listitem" to="/conditions-and-treatment">Conditions & Treatment</Link>
              <Link className="listitem" to="/blog">Blog</Link>
              <Link className="listitem" to="/testimonial">Testimonial</Link>
              {/*<Link className="listitem" to="/media">Media</Link> */}
              <Link className="listitem" to="/contact-us">Contact Us</Link>
              <div className="socialicons_header_swiper">
              <SocialIcons override />
              </div>
            </div>
            <div className={`mobilemoreDiv animatedtransition right ${tabnav && 'toggleanimateclass'}`}>
              <Nav.Link className="backnavdiv" onClick={() => settabnav(0)}><i className="fas fa-chevron-left"></i> Back</Nav.Link>
              <MenuChildrenMobile onhide={()=>setdrawerstate(false)}  data={menuitems[tabnav]} />
            </div>
          </SwipeableDrawer>
        </Navbar>
        </Fragment>
      }
    </Fragment>
  )
}
export default Header;