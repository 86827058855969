import React, { Fragment, useState, useEffect } from 'react';
import { Carousel, Row, Col, Container, Modal, Tabs, Tab, Card, Image, Pagination } from 'react-bootstrap';
import Landing from '../components/Landing';
import ConsultForm from '../components/Forms/consultform';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import { InformationBox, SocialIcons } from '../components/HelperComponents';
import { NavLink } from 'react-router-dom';
import ReactSvgcomp from '../components/SvgComp';
import FixedMenu from '../components/FixedMenu';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import MetaTags from 'react-meta-tags';
import FooterPageComponent from '../components/FooterPageComponent';
import { useHistory, HashRouter, Route, Switch, BrowserRouter as Router, Redirect, useLocation } from "react-router-dom";
import TestimonialDetailComponent from '../components/TestimonialDetailComponent';


const rendertestimonalcard = (obj, testimonal_path) => {
  return (
    <div>
      {obj.length > 0 ? obj.map((cardobj, cardindex) => {
        return (
          <div className="testicard">
            <Row style={{ padding: "10px" }} >
              <Col md={3} lg={3} style={{ width: "300px", overflow: "hidden" }}>
                {cardobj.patient_image != 'common_avatar' && <div style={{ overflow: "hide" }}>
                  <img src={ImageURL(cardobj.patient_image)} width="100%"></img>
                </div>
                }
                {cardobj.patient_image == 'common_avatar' && <div>
                  <img src={ImageURL(cardobj.image)} width="100%"></img>
                </div>
                }
              </Col>
              <Col md={8} lg={8} style={{ padding: "10px", paddingBottom: "0px" }} >
                <p><h3>{cardobj.name}</h3>
                  <p >{cardobj.occupation}</p>
                  <hr style={{ margin: "5px 0px" }} />
                  <p >{cardobj.testimonial}</p>
                </p>
              </Col>
            </Row>
          </div>

        )
      }) : <p style={{ color: 'red' }}>No records found</p>}
    </div>
  )
}
const renderpagination = (obj, active_number, setactive) => {
  let active = active_number;
  let items = [];
  if (obj <= 0) {
    return "";
  }
  for (let number = 1; number <= obj; number++) {
    items.push(
      <Pagination.Item onClick={() => setactive(number)} key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <Pagination style={{ flexWrap: "warp" }}>
      {items}
      <div className="next_page">
        <Pagination.Next onClick={() => active_number == obj ? setactive(1) : setactive(active_number + 1)} />
      </div>
    </Pagination>
  )
}

const Testimonal = (props) => {
  const [testimonalobj, settestimonalobj] = useState('All');
  const [testimonalcontent, settestimonalcontent] = useState(null);
  const [testimonalData, settestimonalData] = useState(null);
  const [limit, setlimit] = useState(50);
  const [active, setactive] = useState(1);
  const isDev = process.env.REACT_APP_ENV == 'isDev';
  const isProd = process.env.REACT_APP_ENV == 'isProd';
  var home_path = "http://localhost:3000/";
  if (isDev) {
    home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
  } else if (isProd) {
    home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
  }
  const updateContent = () => {
    fetch(home_path + 'testimonal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((resp) => {
        console.log(resp);
        return resp.ok ? resp.json() : null
      })
      .then((response) => {
        settestimonalcontent((response && response.length > 0) ? response[0] : null);
        var arraytestimonal = [];
        if (response && response.length > 0) {
          const find_testimonal = response[0].content.testimonals.filter((obj) => obj.title != 'All').map((obj) => arraytestimonal = arraytestimonal.concat(obj.content));
          var i = 0;
          arraytestimonal.forEach(t => {
            i++;
            if (i > 110) {
              i = 1;
            }
            var str = "" + i
            var pad = "0000"
            var seq = pad.substring(0, pad.length - str.length) + str
            if (t.image == 'default') {
              t.image = "/images/common/testimonial/testimonial_" + seq + ".jpg";
            }
          })
          settestimonalData(arraytestimonal);
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          });
        }
        // settestimonalData((response&&response.length>0)?response[0].content.testimonals[0].content:null)

      })
  }
  useEffect(() => {
    updateContent();
    // window.scrollTo({top:0, behavior: 'smooth'})
  }, [])
  const resettestimonals = (title) => {
    settestimonalobj(title);
    if (title == 'All') {
      updateContent();
    } else {
      var filter_testimonals = testimonalcontent.content.testimonals.find((obj) => obj.title == title);
      settestimonalData(filter_testimonals && filter_testimonals.content);
    }
    setactive(1);
  }
  const totalpage = Math.ceil(testimonalData ? (testimonalData.length / limit) : 0);
  const splicedData = testimonalData && JSON.parse(JSON.stringify(testimonalData)).splice((active == 1 ? active - 1 : (((active - 1) * limit))), limit);
  return (
    <Fragment>
      <Route exact path={`${props.match.path}/`}>
        <Landing footerbg={"#ffffff"} active={"testimonal"}>
          <MetaTags>
            <title>Testimonial</title>

            {testimonalcontent && testimonalcontent.meta && testimonalcontent.meta.length > 0 && testimonalcontent.meta.map((m, i) => {
              return (
                <meta name={m.name} content={m.content} />
              )
            })}

          </MetaTags>
          {testimonalcontent &&
            <div className="virtualconsulation_div testimonal_div">
              <FixedMenu />
              <Banner className="bannerimage" bannerurl={ImageURL(testimonalcontent.content.banner)} >
                <div class="banner-text">
                  <h3>{testimonalcontent.content.title}</h3>
                </div>
              </Banner>
              <Tabs defaultActiveKey={testimonalobj} id="uncontrolled-tab-example" className="testimonaltabs" onSelect={(k) => resettestimonals(k)}>
                {testimonalcontent && testimonalcontent.content.testimonals.map((obj, index) => {
                  return (
                    <Tab tabClassName={`${([2, 3].includes((index + 1) % 4)) ? 'tabmarginright' : ''}`} eventKey={obj.title} title={obj.title}>
                      {testimonalData && testimonalobj == obj.title && splicedData &&
                        <Container>
                          {rendertestimonalcard(splicedData, props.match.url)}
                          {renderpagination(totalpage, active, setactive)}
                        </Container>
                      }
                    </Tab>
                  )
                })}
              </Tabs>
              <div className="mobiletabs">
                {testimonalcontent && testimonalcontent.content.testimonals.map((obj, index) => {
                  return (
                    <div>
                      <a className={`mobiletab ${testimonalobj == obj.title && 'active'}`} onClick={() => resettestimonals(obj.title)}>{obj.title}</a>
                      {
                        testimonalobj == obj.title && splicedData &&
                        <Container>
                          {rendertestimonalcard(splicedData, props.match.url)}
                          {renderpagination(totalpage, active, setactive)}
                        </Container>
                      }

                    </div>
                  )
                })}
              </div>
            </div>
          }
        </Landing>
      </Route>
      {testimonalcontent && testimonalcontent.content.testimonals.map((obj) => {
        return obj.content.map((obj1) => {
          return (
            <Route exact path={`${props.match.path}/${obj1.read_more_link}`}>
              <TestimonialDetailComponent path="testimonals" title="Testimonal" patientDetail={obj1} loadjson={`${obj1.read_more_link}`} />
            </Route>
          )
        })
      })}
    </Fragment>

  )
}
export default Testimonal;