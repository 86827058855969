import React,{Fragment,useState} from 'react';
import {Navbar,Nav,NavDropdown,ButtonGroup,Button,ListGroup,Image} from 'react-bootstrap'
import logo from '../images/logo.png';
import MenuChildren from './MenuChildren';
import Images,{ImageURL} from './Images';
import {SocialIcons} from './HelperComponents';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import menuitems from '../data/menudata.json';
import parse from 'html-react-parser';
import {NavLink} from 'react-router-dom';
import {Banner} from '../sytled-components/Banner';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
const MenuChildrenMobile=(props)=>{
	// alert(JSON.stringify(props.data))
	// console.log(props.data);
	const content=props.data;
	const renderlistContent=function(data,type){
		console.log('data',type);
		if(type==1){
			return data.map((obj)=>{
			return(
				<ListGroup.Item as="li">
				<h6>{obj.title}</h6>
				<p>{obj.address}</p>
				<p>{parse(obj.timings)}</p>	
				<h6>{obj.venue}</h6>	
				<p>{obj.venueaddress}</p>
				<p>{parse(obj.type)}</p>
				</ListGroup.Item>
				)
			})
		}else if(type==2){
			return data.map((obj)=>{
				return(
					<ListGroup.Item as="li" className="flexrow">
					 <Image className="rounderimage" src={obj.image} roundedCircle />
					 <div>
					<h6>{obj.title}</h6>	
					<p>{obj.subtitle}</p>
					</div>
					</ListGroup.Item>
					)
			})
		}else if(type==3 || type==4){
			return data.map((obj)=>{
			return(
				<ListGroup.Item as="li">
				<h6><NavLink onClick={()=>props.onhide&&props.onhide()} to={obj.url}>{obj.title}</NavLink></h6>
				</ListGroup.Item>
				)
			})
		}
	}
return(
	<div>
		<ListGroup as="ul">
		{content&&content.map((obj)=>{
			return(
				<Fragment>
					  <ListGroup.Item  target="_blank" href={obj.href} className={`first ${obj.href?'listanchortag':''}`} as={obj.href?'a':'li'} active={obj.content&&obj.content.length>0}>
    {obj.title}
  </ListGroup.Item>
  {obj.content&&renderlistContent(obj.content,obj.type)}
  </Fragment> 
				)
		})}

  </ListGroup>

	</div>
	)
}
export default MenuChildrenMobile;