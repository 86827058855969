import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {Spinner} from 'react-bootstrap';
import {CurvedLayout,CurvedButton,CurvedInput} from '../../sytled-components/curvedlayout';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import apiservice from '../../utils/apiservices';
const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name is too short')
    .max(50, 'Too Long!')
    .required('name is too short'),
  mobile: Yup.string()
    .required('Enter Mobile Number'),
  email: Yup.string()
    .email('Invalid email')
    .required('Invalid email'),
});
const appointmentHtml=(data)=>{
  return `<html>
          <body>
          <table cellspacing="15">
          <tr>
          <th align="left">Name </th>
          <td> : ${data.name}</td>
          </tr>
          <tr>
          <th align="left">Mobile </th>
          <td> : ${data.mobile}</td>
          </tr>
          <tr>
          <th align="left">Email </th>
          <td> : ${data.email}</td>
          </tr>
          </table>
          </body>
          </html>`
}
const AppointmentForm = () => {
  const [loading,setloading]=useState(null);
  return(
  <div>
    <Formik
      enableReinitialize
      initialValues={{
        name: '',
        mobile: '',
        email: '',
        checked:true
      }}
      validationSchema={SignupSchema}
      onSubmit={(values,{setSubmitting, setErrors, setStatus, resetForm}) => {
        var payload={
      "form_type":"Call Back Request",
       "sender_email":values.email,
      "message_html": appointmentHtml(values) 
      }
      // alert(JSON.stringify(payload));
   if(!values.checked){
    alert("Please accept the terms & conditions")
   }else{
        setloading(true)
        apiservice.sendMailJet(payload).then((resp)=>{
          alert('mail sent');
          setloading(false);
          resetForm({});
        })
   }
      }}
    >
      {({ errors, touched,values,setFieldValue }) => (
        <Form className="appointmentForm" id="appointmentform">
        <label>
          <Field name="name">
           {({
                          field, // { name, value, onChange, onBlur }
                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                      }) => (
                      <CurvedInput placeholder="Name" {...field}/>
                      )}
                      </Field>
          {errors.name && touched.name ? (
            <div className="errordiv">{errors.name}</div>
          ) : null}
          </label>
          <label>
          <Field name="mobile"   >
          {({
                          field, // { name, value, onChange, onBlur }
                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                           ...props
                      }) => (
                      <CurvedInput placeholder="Phone Number" type={isMobile?"number":"text"}   {...props}  {...field} onChange={(e)=>setFieldValue('mobile',e.target.value.replace(/[^0-9]+/g, ''))}/>
            )}
          </Field>
          {errors.mobile && touched.mobile ? (
            <div className="errordiv">{errors.mobile}</div>
          ) : null}
          </label>
          <label>
          <Field name="email"    >
          {({
                          field, // { name, value, onChange, onBlur }
                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                      }) => (
                      <CurvedInput type="email" placeholder="Email" {...field}/>
            )}
          </Field>
          {errors.email && touched.email ? <div className="errordiv">{errors.email}</div> : null}
          </label>
          <div className="customcheckbox">
          <label  className="radiocontainer">
            <input onChange={()=>setFieldValue('checked',!values.checked)} type="checkbox" checked={values.checked}/>
            <span className="checkmark"></span>
            <p className="conditiontext">I Agree to <a href="/terms-and-conditions" target="_blank">terms & conditions</a></p>
          </label>
          </div>
          <CurvedButton className="formbtn" bgcolor={"#FFB000"}>
          {loading?<Spinner animation="border" />:'Request a call back'}
          </CurvedButton>
        </Form>
      )}
    </Formik>
  </div>
)};
export default AppointmentForm;