import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import parse from 'html-react-parser';
import Images,{ImageURL} from '../Images';
import {NavLink} from 'react-router-dom';
import {Banner} from '../../sytled-components/Banner';
const TreatmentComponent =(props)=>{
	const parentMenuImage = props.content.image;
	const content=props.content.content;
	const [contentData,setContent]=useState(null);
	const [hoveredimage,sethoveredimage]=useState(null);
useEffect(()=>{
	var getlength=Math.floor(content.length/2);
	var remainder=content.length%2;
	var splittedArray=[];
	var contentclone=JSON.parse(JSON.stringify(content));
	 //adding splittedrecords
    for (var i = 0; i < 2; i++) {
        splittedArray.push(contentclone.splice(0, getlength))
    }
    //adding remaining records
    if (remainder > 0&&splittedArray.length>0) {
        splittedArray[0]=splittedArray[0].concat(contentclone);
    }
	// console.log((splittedArray));
	setContent(splittedArray);
	// alert(remainder);
},[])
	// alert(JSON.stringify(content));
	return(
		<div className="treatmentcomponentdiv">
		<Container>
				<Row className="treatmentrow">
		{contentData&&contentData.length>0&&contentData.map((obj,index)=>{
			return(
				<Col className="treatmentcol" lg={4}>
				<ul>
				{obj.map((obj)=>{
					return(
						<li onMouseOver={()=>sethoveredimage(obj.image)} onMouseLeave={()=>sethoveredimage(null)}><NavLink to={`/${obj.url}`} onClick={()=>props.onhide&&props.onhide()}>{obj.title}</NavLink></li>
						)
				})}
				</ul>
				</Col>
				)
		})}
		<Col lg={{offset:0,span:3}} className="treatmentcol last treatmentcolbanner">
		{hoveredimage?
			<Banner className="treatmentbanner" bannerurl={ImageURL(hoveredimage)?ImageURL(hoveredimage):ImageURL('altimage')}/>:
			parentMenuImage?<Banner className="treatmentbanner" bannerurl={ImageURL(parentMenuImage)}/>: 
			<Banner className="treatmentbanner" bannerurl={ImageURL('bone')}/>
			
		}
		</Col>
		</Row>
		</Container>
		</div>
		)
}
export default TreatmentComponent;