import React, { Fragment, useState, useEffect } from 'react';
import { Carousel, Row, Col, Container, Modal, Tabs, Tab, Card, Image, Pagination } from 'react-bootstrap';
import Landing from '../components/Landing';
import ConsultForm from '../components/Forms/consultform';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import { InformationBox, SocialIcons } from '../components/HelperComponents';
import ReactSvgcomp from '../components/SvgComp';
import { Spinner } from 'react-bootstrap';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import FixedMenu from '../components/FixedMenu';
import MetaTags from 'react-meta-tags';
const rendermediacard = (obj, blogdata, setmediadata, pindex) => {
  const updatemedia = (index) => {
    console.log(pindex, blogdata, obj);
    var newblogdata = JSON.parse(JSON.stringify(blogdata));
    newblogdata.content.blogs[pindex].content[index].loaded = true;
    console.log("blogdata", newblogdata);
    setmediadata(newblogdata);
  }
  return (
    <div>
      {obj.content.map((cardobj, cardindex) => {
        return (
          <Row style={{ height: "200px", margin: "20px" }}>
            <Col>
              <Card className="personalCard" style={{ overflow: "hidden", height: "200px", padding: "10px" }}>
                <Row className="peronalrow mr-0 ml-0">
                  <Col md={4} xs={5} className="pl-0 pr-0">
                    <div class="hover14" style={{ overflow: "hidden", height: "180px" , border:"1px solid rgb(207 211 212 / 0.51)"}}>
                      {cardobj.type == 'other' &&
                        <figure>
                          <Card.Img variant="top" src={ImageURL(cardobj.header)} />
                        </figure>
                      }
                      {cardobj.type == 'video' &&
                        <iframe width="100%" height={180} frameBorder={0} src={`https://www.youtube.com/embed/${cardobj.header}?rel=0`} allowFullScreen={true}>
                        </iframe>
                      }
                    </div>
                  </Col>
                  <Col className="pl-0 pr-0">
                    <Card.Body className="pt-3 pl-2">
                      <Card.Text className="">
                        <h5>{cardobj.title}</h5>
                        <p>{cardobj.desc}</p>

                        {cardobj.references.map((ref, index) => {
                           return ( <div className="btn btn-info" style={{margin:"5px"}}><a href={ref.link} style={{color:"#fff"}} target="_blank">{ref.title}</a></div> )
                        })}

                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>


            </Col>
          </Row>
        )
      })}
    </div>
  )
}
const renderpagination = (obj) => {
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <Pagination>
      {items}
      <div className="next_page">
        <Pagination.Next />
      </div>
    </Pagination>
  )
}
const Media = () => {
  const [blogobj, setblogobj] = useState('Home');
  const [blogdata, setblogdata] = useState(null);
  const isDev = process.env.REACT_APP_ENV == 'isDev';
  const isProd = process.env.REACT_APP_ENV == 'isProd';
  var home_path = "http://localhost:3000/";
  if (isDev) {
    home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
  } else if (isProd) {
    home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
  }
  const updateContent = () => {
    fetch(home_path + 'media.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((resp) => {
        console.log(resp);
        return resp.ok ? resp.json() : null
      })
      .then((response) => {
        // console.log("mediajson",response);
        setblogdata((response && response.length > 0) ? response[0] : null);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        });
        // settestimonalData((response&&response.length>0)?response[0].content.testimonals[0].content:null)

      })
  }
  useEffect(() => {
    updateContent();
    // window.scrollTo({top:0, behavior: 'smooth'})
  }, [])
  console.log('blogdata', blogdata);
  return (
    <Landing footerbg={"#ffffff"} active="media">
      <MetaTags>
        <title>Media</title>

        {blogdata && blogdata.meta && blogdata.meta.length > 0 && blogdata.meta.map((m, i) => {
					return (
						<meta name={m.name} content={m.content}/>
					)
				})}

      </MetaTags>
      {blogdata &&
        <div className="virtualconsulation_div testimonal_div media_div">
          <FixedMenu />
          <Banner className="bannerimage" bannerurl={ImageURL(blogdata.content.banner)} >
            <div class="banner-text">
              <h3>{blogdata.content.title}</h3>
            </div>
          </Banner>
          <Tabs defaultActiveKey={blogdata.content.blogs[0].title} id="uncontrolled-tab-example" className="testimonaltabs col3">
            {blogdata.content.blogs.map((obj, index) => {
              return (
                <Tab eventKey={obj.title} title={obj.title}>
                  <Container>
                    {rendermediacard(obj, blogdata, setblogdata, index)}
                  </Container>
                </Tab>
              )
            })}
          </Tabs>
          <div className="mobiletabs">
            {blogdata.content.blogs.map((obj, index) => {
              return (
                <div>
                  <a className={`mobiletab ${blogobj == obj.title && 'active'}`} onClick={() => setblogobj(obj.title)}>{obj.title}</a>
                  {
                    blogobj == obj.title &&
                    <Container>
                      {rendermediacard(obj, blogdata, setblogdata, index)}
                    </Container>
                  }

                </div>
              )
            })}
          </div>
        </div>
      }
    </Landing>
  )
}
export default Media;