import React,{Fragment,useState} from 'react';
import {Carousel,Container,Row,Col} from 'react-bootstrap';
import Images,{ImageURL} from '../components/Images';
const Insurers=(props)=>{
   
	return(
        <Fragment>
            <div className="insurer_main">
            {props.title&&<div className="title">{props.title}</div>}
       <div className="container">
           <Row className="row  bottom_space">
           {props.images&&props.images.map((obj,index)=>{
            return(
              <Col key={`insure_image_${index}`} md={3} lg={2} sm={4} xs={6} className=" imagecol ">
                   <img src={ImageURL(obj.image)} className="img-fluid" alt="noimage"/>
                   <div className="img_text ">{obj.title}</div>
                   </Col>
              )
           })}
           </Row>
           <div className="row justify-content-center bottom_space">
              
           </div>
       </div>
</div>
    </Fragment>
    )
    }
    export default Insurers;