import apiCrud from './apicrud';
const emailurl = "https://www.londonupperlimbsurgery.co.uk/mail/apis/mailSender.php";

const mailJeturl = "https://www.londonupperlimbsurgery.co.uk/mail/apis/mailjet.php";

const apiservice = {
	sendappointment: async function (data) {
		var response = await apiCrud.postData(emailurl, data);
		return response;
	},
	sendMailJet: async function (data) {
		var response = await apiCrud.postData(mailJeturl, data);
		return response;
	}
}
export default apiservice;