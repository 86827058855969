import React, { Fragment, useState, useEffect } from 'react';
import { Carousel, Row, Col, Container } from 'react-bootstrap';
import Landing from '../components/Landing';
import ConsultForm from '../components/Forms/consultform';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import FixedMenu from '../components/FixedMenu';
import MetaTags from 'react-meta-tags';

const VirtualConsultation = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <Landing footerbg={"#ffffff"} active="virtual">
            <MetaTags>
                <title>Virtual Consultation</title>
                <meta name="keyword" content="Virtual Consultation with Karthik Karuppaiah " />
                <meta name="description" content="Virtual Consultation with Karthik Karuppaiah " />
            </MetaTags>
            <div className="virtualconsulation_div">
                <FixedMenu />
                <Banner className="bannerimage" bannerurl={ImageURL('virtual')} >
                    <div class="banner-text">
                        <h3>Virtual Consultation</h3>
                    </div>
                </Banner>
                <Container>
                    <div className="formtexts">
                        <p className="pt-2">To request an appointment, please enter the information and press the "submit" button when you are through.</p>
                        <p className="pt-2">( <span>*</span> ) Your name and phone number or email are required fields, so that we can contact you to confirm your appointment</p>
                    </div>
                    <ConsultForm type="Virtual Consultation" />
                </Container>
            </div>
        </Landing>
    )
}
export default VirtualConsultation;