import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import parse from 'html-react-parser';
const PracticeComponent =(props)=>{
	// alert(JSON.stringify());
	const content=props.content;
	return(
		<div className="practisecomponentdiv">
		<Container>
		{content&&content.map((obj)=>{
			return(
				<Row className="practiserow">
		<Col className="practisecol" lg={3}>
		<section>
		<h4>{obj.title}</h4>	
		<p>{obj.address}</p>
		</section>
		</Col>
		<Col className="practisecol" lg={3}>
		<section>
		<p>{parse(obj.timings)}</p>	
		</section>
		</Col>
		<Col className="practisecol" lg={3}>
		<section>
		<h4>{obj.venue}</h4>	
		<p>{obj.venueaddress}</p>
		</section>
		</Col>
		<Col className="practisecol" lg={3}>
		<section>
		<p>{parse(obj.type)}</p>
		</section>
		</Col>
		</Row>
				)
		})}
		</Container>
		</div>
		)
}
export default PracticeComponent;