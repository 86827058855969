import React, { Fragment, useState, useEffect } from 'react';
import { Carousel, Row, Col, Container, Modal } from 'react-bootstrap';
import Landing from '../components/Landing';
import ConsultForm from '../components/Forms/consultform';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import { InformationBox, SocialIcons } from '../components/HelperComponents';
import ReactSvgcomp from '../components/SvgComp';
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile
} from "react-device-detect";
import FixedMenu from '../components/FixedMenu';
import MetaTags from 'react-meta-tags';
const hospitaljson = [{ name: "London Bridge Hospital", map: "", "secretary": "Secretary: Doreen Rowlay", 'address': "London Bridge Hospital,London", mobile: "020 3299 1306", "email": "doreen.rowlay@nhs.net", image: 'lbhospital' },
{ name: "BMI The Blackheath Hospital", map: "", "secretary": "Secretary: Doreen Rowlay", 'address': "BMI The Blackheath Hospital,London", mobile: "020 3299 1306", "email": "doreen.rowlay@nhs.net", image: 'bmihospital' },
{ name: "Chelsfield Park Hospital", map: "", "secretary": "Secretary: Doreen Rowlay", 'address': "Chelsfield Park Hospital,London", mobile: "020 3299 1306", "email": "doreen.rowlay@nhs.net", image: 'chelshospital' },
{ name: "Kings College Hospital", map: "", "secretary": "Secretary: Doreen Rowlay", 'address': "King College Hospital,London", mobile: "020 3299 1306", "email": "doreen.rowlay@nhs.net", image: 'kingshospital' }]
const Contact = () => {
	const [currenthospital, setcurrenthospital] = useState(null);
	const [contactData, setcontactData] = useState(null);
	const sethospital = (data) => {
		setcurrenthospital(data);
		setTimeout(() => {

			if (window.innerWidth > 768 && document.querySelector('#mapcontentdiv')) {
				window.scrollTo({
					top: document.querySelector('#mapcontentdiv').offsetTop - 180,
					left: 0,
					behavior: 'smooth'
				});
			}
		}, 200)
	}
	const isDev = process.env.REACT_APP_ENV == 'isDev';
	const isProd = process.env.REACT_APP_ENV == 'isProd';
	var home_path = "http://localhost:3000/";
	if (isDev) {
		home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
	} else if (isProd) {
		home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
	}
	const updateContent = () => {
		fetch(home_path + 'contactus.json', {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then((resp) => {
				console.log(resp);
				return resp.ok ? resp.json() : null
			})
			.then((response) => {
				// console.log("mediajson",response);
				setcontactData((response && response.length > 0) ? response[0] : null);
				setTimeout(() => {
					window.scrollTo({ top: 0, behavior: 'smooth' })
				});
				// settestimonalData((response&&response.length>0)?response[0].content.testimonals[0].content:null)

			})
	}
	useEffect(() => {
		updateContent();
		// window.scrollTo({top:0, behavior: 'smooth'})
	}, [])
	// useEffect(()=>{
	// window.scrollTo({top:0, behavior: 'smooth'})
	// },[])
	return (
		<Landing footerbg={"#ffffff"} active="contact">
			<MetaTags>
				<title>Contact Us</title>

				{contactData && contactData.meta && contactData.meta.length > 0 && contactData.meta.map((m, i) => {
					return (
						<meta name={m.name} content={m.content}/>
					)
				})}
				
			</MetaTags>
			{contactData &&
				<div className="virtualconsulation_div contactus">

					<FixedMenu />
					<Banner className="bannerimage" bannerurl={ImageURL('banner_bg')}>
						<header>
							{contactData.content.title &&
								<h2>{contactData.content.title}</h2>
							}
						</header>
					</Banner>
					<div className="hospitals">
						<Container>
							<h5>{contactData.content.hospitals.title}</h5>
							<Row>
								<Col lg={9} md={12}>
									<Row>
										{contactData.content.hospitals.content.map((obj, index) => {
											return (
												<Col onClick={() => sethospital(obj)} key={"hospitalIndex" + index} className="hospital_img" lg={3} md={3}><img src={ImageURL(obj.image)} /><p className={`hospital_title ${currenthospital && currenthospital.name == obj.name && 'active'}`}>{obj.name}</p></Col>
											)
										})}
									</Row>
								</Col>
								<Col lg={3} md={12} className="rightcontactdiv">
									{contactData.content.hospitals.contactDetails.map((obj, index) => {
										return (
											<Fragment>
												<h5>{obj.title}</h5>
												{obj.description && <p>{obj.description}</p>}
												{obj.lists && <p>{obj.lists.map((obj1, index1) => {
													return (
														<Fragment>{obj1}<br /></Fragment>
													)
												})}</p>}
												{obj.iconlists && obj.iconlists.map((obj2, index2) => {
													return (
														<div>
														{obj2.url && 
															<a href={obj2.url} target="_blank">
															<div className="contacticon">
																<div className="contactsvg">
																	<ReactSvgcomp className="iconsvg" src={ImageURL(obj2.image)} />
																</div>
																<label>{obj2.text}</label>
															</div>
															</a>
														}

														{!obj2.url && 															
															<div className="contacticon">
																<div className="contactsvg">
																	<ReactSvgcomp className="iconsvg" src={ImageURL(obj2.image)} />
																</div>
																<label>{obj2.text}</label>
															</div>
														}
														</div>
													)
												})}
											</Fragment>
										)
									})}


								</Col>
							</Row>
						</Container>
					</div>
					<Container>
						{currenthospital && (window.innerWidth <= 768 || isMobile) &&
							<Modal show={true} onHide={() => setcurrenthospital(null)}>
								<Modal.Header closeButton>
									<Modal.Title>{currenthospital.name}</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Row className="mapcontentdiv1">
										<Col lg={9}>
											<iframe
												width="100%"
												height="350"
												frameborder="0"
												src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDS9ePaBsFgdZt5v2wQrciYrLGhVJmvTWE
												&q=${currenthospital.map ? currenthospital.map : currenthospital.name}`} allowfullscreen>
											</iframe>
										</Col>
										<Col lg={3} md={12} className="rightcontactdiv">
										</Col>
									</Row>
								</Modal.Body>
							</Modal>
						}
						{currenthospital && isBrowser &&
							<Row className="mapcontentdiv" id="mapcontentdiv">
								<Col lg={9}>
									<iframe
										width="100%"
										height="450"
										frameborder="0"
										src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDS9ePaBsFgdZt5v2wQrciYrLGhVJmvTWE
    &q=${currenthospital.map ? currenthospital.map : currenthospital.name}`} allowfullscreen>
									</iframe>
								</Col>
								<Col lg={3} md={12} className="rightcontactdiv">
								</Col>
							</Row>
						}
						<div className="formtexts">
							<h3>Virtual Consultation</h3>
							<p className="pt-2">For virtual consultation, please enter the information and press the "submit" button when you are through.</p>
							<p className="pt-2">( <span>*</span> ) Your name and phone number or email are required fields, so that we can contact you to confirm your appointment</p>
						</div>
						<ConsultForm type="Virtual Consultation" />
					</Container>
				</div>
			}
		</Landing>
	)
}
export default Contact;