import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import Images, { ImageURL } from '../Images';
import { Banner } from '../../sytled-components/Banner';
const StaffComponent = (props) => {
	const content = props.content;
	return (
		<div className="staffcomponentdiv">
			<Container>
				<Row className="staffrow">
					{content && content.map((obj, index) => {
						return (
							<Col className={`staffcol ${index == content.length - 1 ? 'last' : ''}`} lg={4}>
								<section>
									<h4>{obj.title}</h4>
									<p>{obj.subtitle}</p>
								</section>
								<a href={obj.url} target="_blank">
									<Banner className="staffbanner" bannerurl={ImageURL(obj.image)} />
								</a>
							</Col>

						)
					})}
				</Row>
			</Container>
		</div>
	)
}
export default StaffComponent;