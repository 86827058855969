import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Images, { ImageURL } from '../components/Images';
import { ReactSVG } from 'react-svg';
import { Banner } from '../sytled-components/Banner';
import InnerImageZoom from 'react-inner-image-zoom';


export const renderBodyContent = (content, type, alldata) => {
	console.log("alldata", alldata)
	const titlecolor = alldata ? alldata.titlecolor : "";
	switch (type) {
		case "title":
			return (<h2 className={`${titlecolor ? 'bgtitle' : ''}`}>{content}</h2>)
			break;
		case "images":
			return (<div className="conditional_images space-between flex-wrap">
				{content.content.map((obj) => {
					return (
						<div className="col-lg-6 col-md-6 col-xs-12 condition_banner_col">
							{!obj.zoom &&
								<img className="conditional_banner" src={ImageURL(obj.image)} style={obj.image_width && { width: obj.image_width }} />
							}
							{obj.zoom && <InnerImageZoom src={ImageURL(obj.image)} zoomScale="1.5" />}
							{obj.caption && <p>{obj.caption}</p>}
						</div>
					)
				})}
			</div>)
			break;
		case "para":
			return (<div className="conditional_para">
				{content.title && <p className="para_title">{content.title}</p>}
				{content.content.length > 0 && content.content.map((obj) => {
					return (
						<p className="para">{obj}</p>
					)
				})}
			</div>)
			break;
		case "link":
			return (<div className="conditional_link">
				{content.title && <p>{content.title}</p>}
				{content.content.map((obj) => {
					return (
						<a taget="_blank" href={obj} className="anchorlink">{obj}</a>
					)
				})}
			</div>)
			break;
		case "list":
			return (<div className="conditional_list">
				{content.title && <p>{content.title}</p>}
				{content.content.map((obj) => {
					return (
						<li className="list"><i className="fas fa-circle" style={{ fontSize: "10px" }}></i><span>{obj}</span></li>
					)
				})}
			</div>)
			break;
		case "avatarimage":
			return (<div className="avatarimage">
				<Row>
					<Col md={6} lg={6}>
						<Banner className="banner_avt_img" bannerurl={ImageURL(content.content.image)} />
						<p className="avt_h1">{content.content.name}</p>
						<p className="avt_h2">{content.content.date}</p>
					</Col>
					<Col md={6} lg={6} className="avt_right">
						{content.content.showviews &&
							<div className="svgicon">
								<ReactSVG src={`${ImageURL('eye')}`} />
							</div>
						}
						{content.content.showcomments &&
							<div className="svgicon">
								<img src={`${ImageURL('message')}`} alt={'message'} />
							</div>
						}
						{content.content.rightcontent &&
							<div className="inline-flex flex-wrap avt_right_flex">
								<div className="avt_rightcontent" style={{ marginRight: "100px" }}>
									{content.content.rightcontent.map((obj) => {
										return (
											<span>
												{obj.title && <h5>{obj.title}</h5>}
												{obj.subtitle}<br />
											</span>
										)
									})}
								</div>

								{/*	{content.content.rightcontent.title &&
										<h5>{content.content.rightcontent.title}</h5>
									}
									{content.content.rightcontent.subtitle &&
										<p>{content.content.rightcontent.subtitle}</p>
									}
									<Banner className="banner_avt_img ml-auto" bannerurl={ImageURL(content.content.rightcontent.image)} />
								*/}


							</div>
						}
					</Col>
				</Row>
			</div>)
			break;
		case "blogHeader":
			return (<div className="avatarimage">
				<Row>
					<Col md={6} lg={6}>
					    <p className="avt_title">{content.content.title}</p>
						<p className="avt_h2">By</p>
						<p className="avt_h1">{content.content.name}</p>
						<p className="avt_h2">{content.content.date}</p>
					</Col>
					<Col md={6} lg={6} className="avt_right">
						{content.content.showviews &&
							<div className="svgicon">
								<ReactSVG src={`${ImageURL('eye')}`} />
							</div>
						}
						{content.content.showcomments &&
							<div className="svgicon">
								<img src={`${ImageURL('message')}`} alt={'message'} />
							</div>
						}
						{content.content.rightcontent &&
							<div className="inline-flex flex-wrap avt_right_flex">
								<div className="avt_rightcontent" style={{ marginRight: "100px" }}>
									{content.content.rightcontent.map((obj) => {
										return (
											<span>
												{obj.title && <h5>{obj.title}</h5>}
												{obj.subtitle}<br />
											</span>
										)
									})}
								</div>

								{/*	{content.content.rightcontent.title &&
										<h5>{content.content.rightcontent.title}</h5>
									}
									{content.content.rightcontent.subtitle &&
										<p>{content.content.rightcontent.subtitle}</p>
									}
									<Banner className="banner_avt_img ml-auto" bannerurl={ImageURL(content.content.rightcontent.image)} />
								*/}


							</div>
						}
					</Col>
				</Row>
			</div>)
			break;
		case "description":
			return (<div className={`${titlecolor ? 'pl-2' : ''}`} >{content.map((obj) => {
				return (
					<Fragment>
						{renderBodyContent(obj, obj.type)}
					</Fragment>
				)
			})}</div>)
			break;
		case "table":
			return (<div className="conditional_list">
				{content.title && <p>{content.title}</p>}
				{content.content.map((obj) => {
					return (
						<table class="table table-striped table-bordered" style={{width:obj.width}}>
							<thead>
								<tr>
									{obj.header.map((header) => {
										return (
											<th scope="col">{header.name}</th>
										)
									})}


								</tr>
							</thead>
							<tbody>
								{obj.rows.map((rowz) => {
									return (
									<tr>
										{rowz.row.map((data) => {
											return (
												<td scope="row">{data.value}</td>
											)
										})}
									</tr>
									)
								})}
							</tbody>
						</table>
					)
				})}
			</div>)
			break;

	}

}