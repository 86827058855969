import React, { Fragment, useEffect, useState } from 'react';
import Landing from '../components/Landing';
import Slider from '../components/Carousel';
import Insurers from '../components/Insurers';
import Partners from '../components/Partners';
import Intro from '../components/Intro';
import PersonBlog from '../components/PersonBlog';
import Youtubevideo from '../components/youtubevideo';
import CustomerSlider from '../components/CustomerSlider';
import PageLoader from '../components/PageLoader';
import FixedMenu from '../components/FixedMenu';
import MetaTags from 'react-meta-tags';
import { Spinner } from 'react-bootstrap';
const isDev = process.env.REACT_APP_ENV == 'isDev';
const isProd = process.env.REACT_APP_ENV == 'isProd';
var home_path = "http://localhost:3000/";
if (isDev) {
  home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
} else if (isProd) {
  home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
}
const HomePage = (props) => {
  const [homecontent, sethomecontent] = useState(null);
  const [blogcontent, setblogcontent] = useState(null);
  const [testimonalcontent, settestimonalcontent] = useState(null);

  const updateContent = (url, type) => {
    fetch(home_path + url + '.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((resp) => {
        console.log(resp);
        return resp.ok ? resp.json() : null
      })
      .then((response) => {
        console.log("homejson", response);
        var responsedata = (response && response.length > 0) ? response[0] : null;
        type == 'home' ? sethomecontent(responsedata) : type == 'blog' ? setblogcontent(responsedata) : settestimonalcontent(responsedata);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        });
      })
  }
  useEffect(() => {
    updateContent('home', 'home');
    updateContent('blog', 'blog');
    updateContent('testimonal', 'testimonal');
  }, [])
  return (
    <Landing active={'home'}>
      <MetaTags>
        <title>London Upper Limb Surgery</title>

        {homecontent && homecontent.meta && homecontent.meta.length > 0 && homecontent.meta.map((m, i) => {
					return (
						<meta name={m.name} content={m.content}/>
					)
				})}

      </MetaTags>
      {!homecontent && <PageLoader />}
      {homecontent &&
        <Fragment>
          <Slider {...homecontent.content && homecontent.content.carousel} />
          <Intro {...homecontent.content && homecontent.content.intro} />
          <CustomerSlider {...homecontent.content && homecontent.content} />
          <Youtubevideo {...homecontent.content && homecontent.content.youtube_channel} />          
          {blogcontent &&
            <PersonBlog title="Mr. Karuppaiah’s Blog" content={blogcontent && blogcontent.content.posts} view_more_link="/blog" view_more="View More" />
          }
          <Insurers {...homecontent.content && homecontent.content.insurers} />
          {
            testimonalcontent &&
            <Partners title="What our Patients say" content={testimonalcontent && testimonalcontent.content.testimonals[0].content} view_more_link="/testimonial" view_more="View More" />
          }

        </Fragment>
      }
      <FixedMenu bottom detectclass="intro" scrolleffect />
    </Landing>
  )
}
export default HomePage;