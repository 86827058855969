import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { ReactSelectStyles } from '../reactselect';
import { CurvedLayout, CurvedButton, CurvedInput, CurvedTextArea } from '../../sytled-components/curvedlayout';
import { Spinner } from 'react-bootstrap';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import apiservice from '../../utils/apiservices';
var alphanumerics = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function randomString(length, chars = alphanumerics) {
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
const phoneRegExp = /^[0-9]{10}$/
const SignupSchema = Yup.object().shape({
  mobile: Yup.string()
    .required('Phone number is not valid'),
  email: Yup.string()
    .email('Invalid email')
    .required('Invalid email'),
  fullname: Yup.string()
    .required('Field Required')
});
const consultFormHtml = (data) => {
  return `<html>
          <body>
          <table cellspacing="15">
          ${Object.keys(data).map((obj) => {
    return (
      `<tr>
               <th align="left">${obj}</th>
               <td>${data[obj]}</td>
            </tr>`
    )
  }).join('')}
          </table>
          </body>
          </html>`
}
const ConsultForm = (props) => {
  const [captcha, setcaptcha] = useState(randomString(5))
  const [loading, setloading] = useState(null)
  const [reasons, setreason] = useState([])
  const [modes, setmodes] = useState([])
  const isDev = process.env.REACT_APP_ENV == 'isDev';
  const isProd = process.env.REACT_APP_ENV == 'isProd';
  var home_path = "http://localhost:3000/";
  if (isDev) {
    home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
  } else if (isProd) {
    home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
  }
  const updateContent = () => {
    fetch(home_path + 'consultform.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((resp) => {
        console.log(resp);
        return resp.ok ? resp.json() : null
      })
      .then((response) => {
        setreason((response) ? response.reason : []);
        setmodes((response) ? response.consultation : []);
      })
  }
  useEffect(() => {
    updateContent();
  }, [])
  const updatecaptcha = () => {
    setcaptcha(randomString(5))
  }
  return (
    <div>
      <Formik
        initialValues={{
          mobile: '',
          email: '',
          policy: 'Yes',
          currentreferral: 'Yes',
          preffer_contact: ['Email', 'Phone'],
          consultation: '',
          injurydetails: "",
          fullname: '',
          mode: '',
          check_policy: false
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { resetForm }) => {
          // same shape as initial values
          // console.log(values);
          if (values.check_policy == false) {
            alert("please check the policy box")
          } else if (values.preffer_contact.length ===0) {
            alert("please choose any one of Preferred Contact Method")
          } else {
            // alert('success');
            var formobj = { "Preffered mode of consultation": values.mode, "Full name": values.fullname,  "Problem details": values.injurydetails, "Do you have a current referral from your GP?": values.currentreferral,  "Phone": values.mobile,  "Email Address": values.email, "Preferred Contact Method": values.preffer_contact, "I agree with privacy policy": values.policy };
            var payload = {
              "form_type": props.type,
              "sender_email": values.email,
              "message_html": consultFormHtml(formobj)
            }
            setloading(true);
            apiservice.sendMailJet(payload).then((resp) => {
               alert('mail sent');
               setloading(false);
               resetForm({});
             })

            // console.log(values);
          }
          
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="ConsultForm">
            <Row className="consult_row">     
              <Col lg={4} className="formcol">
                <label>Preferred mode of consultation</label>
                <Select
                  styles={ReactSelectStyles}
                  placeholder={null}
                  onChange={(data) => data && setFieldValue('mode', data.value)}
                  options={modes}
                  placeholder="Select"
                />
                {errors.consultation && touched.consultation ? (
                  <div className="errordiv">{errors.consultation}</div>
                ) : null}

              </Col>
            </Row>
            <Row className="consult_row">
               <Col lg={4} className="formcol">
                <label>Full Name <span className="mandatory">*</span></label>
                <Field name="fullname">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <CurvedInput right type="text"  {...field} />
                  )}
                </Field>
                {errors.fullname && touched.fullname ? <div className="errordiv">{errors.fullname}</div> : null}
              </Col>             
            </Row>
            <Row className="consult_row">
              <Col lg={12} className="formcol">
                <label>Please tell us about your problem</label>
                <Field name="injurydetails">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <CurvedTextArea right type="text"  {...field} />
                  )}
                </Field>
                {errors.injurydetails && touched.injurydetails ? <div className="errordiv">{errors.injurydetails}</div> : null}
              </Col>
            </Row>

            <Row className="consult_row">
              <Col lg={12} className="formcol">
                <Row className="radioform">
                  <Col lg={6}>
                    <label>Do you have a current referral from your GP?</label>
                  </Col>
                  <Col lg={6} className="formcol">
                    <div className="radiobtn">
                      <label onClick={(e) => setFieldValue('currentreferral', 'Yes')} className="radiocontainer">
                        <span>Yes</span>
                        <input type="radio" checked={values.currentreferral == 'Yes'} name="currentreferral" />
                        <span className="checkmark"></span>
                      </label>
                      <label onClick={(e) => setFieldValue('currentreferral', 'No')} className="radiocontainer"><span className="optionname">No</span>
                        <input type="radio" checked={values.currentreferral == 'No'} name="currentreferral" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>             
            </Row>
            <Row className="consult_row">                         
              <Col lg={4} className="formcol">
                <label>Phone<span className="mandatory">*</span></label>
                <Field name="mobile"   >
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    ...props
                  }) => (
                    <CurvedInput right type={isMobile ? "number" : "text"}   {...props}  {...field} onChange={(e) => setFieldValue('mobile', e.target.value.replace(/[^0-9]+/g, ''))} />
                  )}
                </Field>
                {errors.mobile && touched.mobile ? <div className="errordiv">{errors.mobile}</div> : null}
              </Col>              
              <Col lg={4} className="formcol">
                <label>Email Address <span className="mandatory">*</span></label>
                <Field name="email"    >
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <CurvedInput right type="text"  {...field} />
                  )}
                </Field>
                {errors.email && touched.email ? <div className="errordiv">{errors.email}</div> : null}
              </Col>
            </Row>
            <Row className="consult_row">
              <Col lg={12} className="formcol">
                <Row className="radioform">
                  <Col lg={6} className="formcol">
                    <label>Preferred Contact Method</label>
                  </Col>
                  <Col lg={6} className="formcol">
                    <div className="radiobtn">
                      <label  className="radiocontainer squarecontainer"><span className="optionname">Email</span>
                        <Field type="checkbox" name="preffer_contact" value="Email" />
                        <span className="checkmark"></span>
                      </label>
                      <label  className="radiocontainer squarecontainer"><span className="optionname">Phone</span>
                      <Field type="checkbox" name="preffer_contact" value="Phone" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="radioform">
              <Col lg={12} className="formcol">
                <div className="radiobtn">
                  <label className="radiocontainer squarecontainer"><span className="optionname">We care about your privacy. By checking this box you confirm that you have read and understood our privacy policy and consent to provide your personal information to us</span>
                    <input type="checkbox" onClick={() => setFieldValue('check_policy', !values.check_policy)} checked={values.check_policy} name="privacypolicy" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </Col>
            </Row>
             <div className="text-center pt-3 pb-3">
              <CurvedButton type="submit" className="formbtn" bgcolor={"#FFB000"}>
                {loading ? <Spinner animation="border" /> : 'Submit'}
              </CurvedButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};
export default ConsultForm;