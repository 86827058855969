import React from "react";
import { components } from "react-select";

export const DropdownIcon = (props) => {
	 return (
    <components.DropdownIndicator {...props}>
      <i className={`fas fa-fw fa-caret-${props.selectProps.menuIsOpen == false ? 'down' : 'up'}`} />
    </components.DropdownIndicator>
  )
}

export const ReactSelectStyles = {

	indicatorSeparator: () => null,
	control: (provided, state) => ({
		...provided,
    borderRadius:0,
    boxShadow: '0 !important',
    borderTopRightRadius:10,
    border:'1px solid #8e8e8e',
    '&:active':{
    	border:'none',
    }
	}),
  menu: (provided, state) => ({
    ...provided,
    // border: '1px solid red',
  }),
  menuList: (provided, state) => ({
    ...provided,
	  })
}
export const ReactSelectGrubStyles = {

  indicatorSeparator: () => null,
  control: (provided, state) => ({
    ...provided,
    borderRadius:0,
    boxShadow: '0 !important',
    padding:"4px"
  }),
  menu: (provided, state) => ({
    ...provided,
    // border: '1px solid red',
  }),
  menuList: (provided, state) => ({
    ...provided,
    })
}
export const ReactSelectCalcStyles = {

  indicatorSeparator: () => null,
  control: (provided, state) => ({
    ...provided,
    borderRadius:0,
    minHeight: "calc(1.5em + 0.75rem + 7px)",
    boxShadow: '0 !important',
    padding:"4px"
  }),
  menu: (provided, state) => ({
    ...provided,
    // border: '1px solid red',
  }),
  menuList: (provided, state) => ({
    ...provided,
    })
}
