import React,{Fragment,useState} from 'react';
import {Banner} from '../sytled-components/Banner';
import {ImageURL} from '../components/Images';
import {Navbar,Nav,NavDropdown,ButtonGroup,Card,Row,Col,Container} from 'react-bootstrap';

const Youtubevideo =(props)=>{
	return(
<div className="youtubevideodiv">

<Banner bannerurl={props.banner?ImageURL(props.banner):""}>
<Container>
{props.title&&
<h2>{props.title}</h2>
}
<Row>
{props.content&&props.content.map((obj,index)=>{
	return(
		<Col key={`youtube_video_${index}`} lg={4} md={6} xs={12}>
		<Card className="partnercard">
  <iframe width="100%" height={200} frameBorder={0} src={`https://www.youtube.com/embed/${obj.video}?rel=0`} allowFullScreen={true}></iframe>
  <Card.Body>
    <Card.Title>{obj.title}</Card.Title>
    <Card.Text>
      {obj.description}
    </Card.Text>
    <div className="readmore">
    <a href={`https://www.youtube.com/watch?v=${obj.viewlink}`} target="_blank">{obj.viewchannel}</a>
    </div>
  </Card.Body>
</Card>
</Col>
		)
})}
</Row>
<div className="visitchannel">
	 <a href={props.visit_channel_link} target="_blank">{props.visit_channel}</a>
	 </div>
</Container>
</Banner>
</div>
		)
}
export default Youtubevideo;