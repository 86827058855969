import React,{Fragment,useState} from 'react';
import {Navbar,Nav,NavDropdown,ButtonGroup,Container,Row,Col} from 'react-bootstrap';
import {InformationBox,SocialIcons,ShareIcons} from './HelperComponents';
import {NavLink} from 'react-router-dom';
const clinics=[
	{"title":"London Bridge Hospital","subtitle":" The Shard, St Thomas’ Street, London SE1 9BS."},
	{"title":"BMI The Blackheath Hospital","subtitle":"40-42 Lee Terrace, Blackheath, London SE3 9UD."},
	{"title":"BMI Chelsfield Park Hospital","subtitle":"Bucks Cross Rd, Orpington, Kent BR6 7RG"},
	{"title":"BMI The Sloane Hospital","subtitle":"125 Albemarle Road, Beckenham, Kent BR3 5HS"},
	{"title":"Guthrie Clinic","subtitle":" King's College Hospital, Denmark Hill, London SE5 9RS"},
	{"title":"HCA Sevenoaks Medical","subtitle":" Centre Beadle House, Sevenoaks TN13 2JD"}
]
const Footer = (props) =>{
	return(
			<div className="footer_div">
				<span className="scrolltop" onClick={()=>window.scrollTo({top:0, behavior: 'smooth'})}><i className="fas fa-chevron-up"></i></span>
				<div className={`additonal_footer ${props.footerbg?'white':''}`}>
				</div>
				<Container>
				<Row>
				<Col lg={4} md={6} xs={12}>
				<div className="ourclinics">
				<h4>Our Clinics</h4>
				{clinics.map((obj,clinicIndex)=>{
					return(
						<div className="clinicwrap" key={"clincindex"+clinicIndex}>
						<h5>{obj.title}</h5>
						<p>{obj.subtitle}</p>
						</div>
						)
				})}
				</div>
				</Col>
				<Col lg={4} md={6} xs={12}>
				<div className="getintouch">
				<h4>Get in Touch</h4>
				<InformationBox override/>
				<div className="footersocialicons">
				<h4>Tell a Friend</h4>
				<ShareIcons link={window.location.href} override type={2}/>
				</div>
				</div>
				</Col>
				<Col lg={4} md={12} xs={12}>
				<div className="footerlinks">
				<SocialIcons override/>
				<div className="anchorlinks">
					<NavLink to="/disclaimer">Disclaimer</NavLink>
					<NavLink to="/privacy-policy">Privacy Policy</NavLink>
					<NavLink to="/terms-and-conditions">Terms and conditions</NavLink>
					<NavLink to="/cookies-declaration">Cookie declaration</NavLink>
					<NavLink to="/gdpr">GDPR</NavLink>
				</div>
				</div>
				</Col>
				</Row>
				</Container>
			</div>
		)
}
export default Footer;