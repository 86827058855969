import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import './App.css';
import { useHistory, HashRouter, Route, Switch, BrowserRouter as Router, Redirect, useLocation } from "react-router-dom";
import HomePage from './Pages/home'
import VirtualConsultation from './Pages/VirtualConsultation';
import Contact from './Pages/contactus';
import Testimonal from './Pages/Testimonals';
import Media from './Pages/Media';
import Blog from './Pages/Blog';
import ConditionalTreatment from './Pages/Conditional';
import Patient from './Pages/Patient';
import Privacypolicy from './Pages/Privacypolicy';
import Disclaimer from './Pages/Disclaimer';
import GDPR from './Pages/Gdpr';
import CookiesDeclaration from './Pages/CookiesDeclaration';
import Termsandconditions from './Pages/Termsandconditions';
import ConditionsAndTreatment from './Pages/ConditionsAndTreatment';
import NotFound from './Pages/NotFound';
import Awards from './Pages/Awards';
import Publications from './Pages/Publications';
import Research from './Pages/Research';
import Presentations from './Pages/Presentations';
import Others from './Pages/Others';
import {isIE} from 'react-device-detect';
import menudata from './data/menudata.json';
function App() {
  const isDev = process.env.REACT_APP_ENV == 'isDev';
  const isProd = process.env.REACT_APP_ENV == 'isProd';
  const [menudata, setmenuitems] = useState(null);
  let history = useHistory();
  var home_path = "http://localhost:3000/";
  if (isDev) {
    home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
  } else if (isProd) {
    home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
  }
  const updateContent = () => {
    fetch(home_path + 'menudata.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((resp) => {
        console.log(resp);
        return resp.ok ? resp.json() : null
      })
      .then((response) => {
        // console.log("mediajson",response);
        setmenuitems((response) ? response : null);
        // settestimonalData((response&&response.length>0)?response[0].content.testimonals[0].content:null)

      })
  }
  useEffect(() => {
    updateContent();

    // window.scrollTo({top:0, behavior: 'smooth'})
  }, [])
  // useEffect(() => {
  //     return history.listen((location) => { 
  //        console.log(`You changed the page to: ${location.pathname}`) 
  //     }) 
  //  },[history]) 
  return (
    <div>
      {isIE&&<div className="alert alert-danger" style={{textAlign:"center", marginBottom:"0"}}>This browser is not fully supported by the website. For better experience, please use Chrome, Mozilla, Edge and Safari.</div>}
      <Router basename={isDev ? process.env.REACT_APP_PROD_BASE_PATH : process.env.REACT_APP_PROD_BASE_PATH}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/virtual-consultation"><VirtualConsultation /></Route>
          <Route path="/contact-us"><Contact /></Route>
          <Route path="/media" ><Media /></Route>
          <Route path="/conditions-and-treatment" ><ConditionsAndTreatment /></Route>
          <Route path="/blog" component={Blog} />
          <Route path="/testimonial" component={Testimonal} />
          <Route path="/privacy-policy"><Privacypolicy /></Route>
          <Route path="/disclaimer"><Disclaimer /></Route>
          <Route path="/gdpr"><GDPR /></Route>
          <Route path="/cookies-declaration"><CookiesDeclaration /></Route>
          <Route path="/terms-and-conditions"><Termsandconditions /></Route>
          <Route path="/karthik-karuppaiah-awards"><Awards /></Route>
          <Route path="/karthik-karuppaiah-publications"><Publications /></Route>
          <Route path="/karthik-karuppaiah-other-information"><Others /></Route>
          <Route path="/karthik-karuppaiah-researches"><Research /></Route>
          <Route path="/karthik-karuppaiah-presentations"><Presentations /></Route>

          {menudata && menudata.conditional.map((obj, index) => {
            return obj.content.map((contenturl) => {
              return (
                <Route path={`/${contenturl.url}`}> <ConditionalTreatment subfolder={obj.section} allInfo={[menudata.conditional[index]]} title={obj.title} content={null} url={contenturl.url} /></Route>
              )
            })
          })}
          {menudata && menudata.patientinfo.map((obj) => {
            return obj.content.map((contenturl) => {
              return (
                <Route path={`/${contenturl.url}`}><Patient menus={obj.content} title={obj.title} folder={obj.folder} content={null} url={contenturl.url} /></Route>
              )
            })
          })}

        </Switch>
      </Router>
    </div>
  );
}

export default App;
