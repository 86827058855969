import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Navbar, Nav, NavDropdown, ButtonGroup, Button, ListGroup, Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Landing from '../components/Landing';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import { NavLink, useHistory } from 'react-router-dom';
import { InformationBox, SocialIcons } from '../components/HelperComponents';
import FixedMenu from '../components/FixedMenu';
import Select from 'react-select';
import { ReactSelectStyles } from '../components/reactselect';
import MetaTags from 'react-meta-tags';
import { renderBodyContent } from '../utils/extrafunctions';
import PageLoader from '../components/PageLoader';
function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}
const Patient = (props) => {
	const isDev = process.env.REACT_APP_ENV == 'isDev';
	const isProd = process.env.REACT_APP_ENV == 'isProd';
	var Patient_path = "http://localhost:3000/patients/";
	if (isDev) {
		Patient_path = process.env.REACT_APP_PATH_DEV_FILEPATH + process.env.REACT_APP_PATH_PATIENT;
	} else if (isProd) {
		Patient_path = process.env.REACT_APP_PATH_PROD_FILEPATH + process.env.REACT_APP_PATH_PATIENT;
	}
	const history = useHistory();
	const { title, menus, url, folder } = props;
	const [patientcontent, setpatientcontent] = useState(null);
	const [loader, setloader] = useState(true);
	const updateContent = (url) => {
		setloader(true);
		fetch(Patient_path + folder + "/" + url + '.json', {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then((resp) => {
				return resp.ok ? resp.json() : null
			})
			.then((response) => {
				setpatientcontent((response && response.length > 0) ? response[0] : null);
				setloader(null);
			})
	}
	const previous = usePrevious(url);
	useEffect(() => {
		if (previous != url) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			setTimeout(() => {
				updateContent(url);
			}, 200)
		}
	})

	const content = patientcontent;
	return (
		<Landing footerbg={"#ffffff"} active={'patient'}>
			<MetaTags>
				<title>{content && content.pageTitle}</title>

				{content && content.meta && content.meta.length > 0 && content.meta.map((m, i) => {
					return (
						<meta name={m.name} content={m.content}/>
					)
				})}

			</MetaTags>
			<div className="virtualconsulation_div conditional_treatment_div patients_div">
			{loader&&<PageLoader absolute={true} linear={true}/>}
				<FixedMenu direction={'right'} />
				{content &&
					<Fragment>
						<Banner className="bannerimage" bannerurl={ImageURL(content.content.bannerURL)} >
							<div class="banner-text">
								<h3>{title} / {content.pageTitle}</h3>
							</div>
						</Banner>
					</Fragment>
				}
				<section className="bodysection">
					<Tabs className={`${menus && menus.length < 3 ? 'nospacebetween' : ''}`} onSelect={(data) => history.push(data)} activeKey={history.location.pathname} transition={false} id="noanim-tab-example">
						{(menus && menus.map((obj, key) => {
							console.log("menus", (key + 1), ((key + 1) % 4 == (2 || 3)));
							return (
								<Tab tabClassName={`${([2, 3].includes((key + 1) % 4)) ? 'tabmarginright' : ''}`} eventKey={"/" + obj.url} title={obj.title} disabled={obj.href || obj.content && obj.content.length == 0}>
									{content && content.content && content.content.quickLinks.map((obj, index) => {
										return (
											<div id={`content${index + 1}`} className="quicklink_section">
												{Object.keys(obj).map((obj1) => {
													return renderBodyContent(obj[obj1], obj1)
												})}
											</div>
										)
									})

									}

								</Tab>
							)
						}))}
					</Tabs>
				</section>
				{!content && <div className="pl-3 pt-0">Loading....</div>}
			</div>
		</Landing>
	)
}
export default Patient;
