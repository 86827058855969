import React,{useState,useEffect} from 'react';
import {Spinner} from 'react-bootstrap';
const PageLoader=(props)=>{
	return(
		<div  className={`text-center pt-3 ${props.absolute?'absolutefillloader':''}`}>
		{props.linear&&
			
				<div class="linear-activity">
				<div class="indeterminate"></div>
				</div>
			
}
	{!props.linear&&
					<Spinner animation="border" role="status"><span className="sr-only ">Loading...</span></Spinner>
			}
</div>
			
		)
}
export default PageLoader;
