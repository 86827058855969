import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import parse from 'html-react-parser';
import Images,{ImageURL} from '../Images';
import {Banner} from '../../sytled-components/Banner';
import {NavLink} from 'react-router-dom';
const PatientComponent =(props)=>{
	const content=props.content;
	const [hoveredData,sethoveredData]=useState(null);
	const checkcontent=content&&content.length>0;
	// alert(JSON.stringify(content));
	return(
		<div className="treatmentcomponentdiv">
		<Container>
				<Row className="treatmentrow">
				<Col className="treatmentcol" lg={3}>
				<ul>
		{checkcontent&&content.map((obj,index)=>{
					return(
						<li onMouseOver={()=>sethoveredData(obj)} onMouseLeave={()=>sethoveredData(null)}><NavLink onClick={()=>props.onhide&&props.onhide()} to={`/${obj.url}`}>{obj.title}</NavLink></li>
				)
		})}
				</ul>
				</Col>
		<Col lg={{offset:0,span:6}} className="treatmentcol treatmentcolbanner">
		{hoveredData?
			hoveredData.description:(checkcontent&&content[0].description)
		}
		</Col><Col lg={{offset:0,span:3}} className="treatmentcol last treatmentcolbanner">
		{hoveredData?
			<Banner className="treatmentbanner" bannerurl={ImageURL(hoveredData.image)?ImageURL(hoveredData.image):ImageURL('altimage')}/>:
			<Banner className="treatmentbanner" bannerurl={ImageURL('')}/>
		}
		</Col>
		</Row>
		</Container>
		</div>
		)
}
export default PatientComponent;