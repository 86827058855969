import React from 'react';
import { ReactSVG } from 'react-svg'
const ReactSvgcomp=(props)=>{
	return(
<ReactSVG
  src={props.src}
  afterInjection={(error, svg) => {
    if (error) {
      console.error(error)
      return
    }
    console.log(svg)
  }}
  beforeInjection={svg => {
    svg.classList.add(props.className)
  }}
  evalScripts="always"
  fallback={() => <span>Error!</span>}
  loading={() => <span>Loading</span>}
  renumerateIRIElements={false}
  wrapper="span"
  className="wrapper-class-name"
  onClick={() => {
    console.log('wrapper onClick')
  }}
/>
	)
}
export default ReactSvgcomp;
