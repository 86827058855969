import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Container, Row, Col, Nav } from 'react-bootstrap';
import PractiseComponent from './menuchildren/PractiseComponent';
import StaffComponent from './menuchildren/StaffComponent';
import TreatmentComponent from './menuchildren/TreatmentComponent';
import PatientComponent from './menuchildren/PatientComponent';
const MenuChildren = (props) => {
  const menudata = props.data;
  const [activekey, setactivekey] = useState(null);
  useEffect(() => {
    menudata && setactive(menudata);
  }, [])
  const rendermenuschema = function (obj) {
    if (obj.type == 1) {
      return <PractiseComponent content={obj.content} />;
    } else if (obj.type == 2) {
      return <StaffComponent content={obj.content} />;
    } else if (obj.type == 3) {
      return <TreatmentComponent {...props} content={obj} />;
    } else if (obj.type == 4) {
      return <PatientComponent {...props} content={obj.content} />;
    }
  }
  const checkanchortag = (key) => {
    if (menudata && menudata.length > 0) {
      var filteranchortag = menudata.filter((obj) => obj.title == key && obj.href)
      if (filteranchortag.length > 0) {
        // alert('')
        window.open(filteranchortag[0].href, '_blank');
        // window.location.replace(filteranchortag[0].href);
      }
    }
    setactivekey(key);
  }
  const setactive = (menus) => {
    var finddata = menus.find((obj) => obj.content ? obj.content.some((obj1) => ("/" + obj1.url).includes(window.location.pathname)) : false);
    // console.log('finddata',);
    setactivekey(finddata ? finddata.title : menus[0].title);
  }
  return (
    <div className="menuchildrendiv" style={{ display: props.show ? 'block' : 'none' }}>
      <Tabs onSelect={(k) => checkanchortag(k)} className={`menu-col-4 ${props.tabclassname}`} activeKey={activekey} transition={false} id="noanim-tab-example">
        {menudata && menudata.map((obj, key) => {
          return (
            <Tab eventKey={obj.title} title={obj.title} disabled={obj.href == "" || obj.content && obj.content.length == 0}>
              {rendermenuschema(obj)}
            </Tab>
          )
        })}
      </Tabs>
    </div>
  )
}
export default MenuChildren;
