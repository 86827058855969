import React, { Fragment, useState, useEffect } from 'react';
import { Carousel, Row, Col, Container, Modal, Tabs, Tab, Card, Image, Pagination } from 'react-bootstrap';
import Landing from '../components/Landing';
import ConsultForm from '../components/Forms/consultform';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import { InformationBox, SocialIcons } from '../components/HelperComponents';
import ReactSvgcomp from '../components/SvgComp';
import { Spinner } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import FixedMenu from '../components/FixedMenu';
import MetaTags from 'react-meta-tags';
const renderConditonal = (obj) => {
  
  return (
    <ul className="col-12">
      {obj.content.map((cardobj, cardindex) => {
        return (
          <li><Link to={cardobj.url}>{cardobj.title}</Link></li>
        )
      })}
    </ul>
  )
}
const renderpagination = (obj) => {
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <Pagination>
      {items}
      <div className="next_page">
        <Pagination.Next />
      </div>
    </Pagination>
  )
}
const ConditionsAndTreatment = () => {
  const [blogobj, setblogobj] = useState('Shoulder');
  const [blogdata, setblogdata] = useState(null);
  const [menuItems, setMenuItems] = useState(null);
  const isDev = process.env.REACT_APP_ENV == 'isDev';
  const isProd = process.env.REACT_APP_ENV == 'isProd';
  var home_path = "http://localhost:3000/";
  if (isDev) {
    home_path = process.env.REACT_APP_PATH_DEV_FILEPATH;
  } else if (isProd) {
    home_path = process.env.REACT_APP_PATH_PROD_FILEPATH;
  }
  const updateContent = () => {
    fetch(home_path + 'menudata.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((resp) => {
        console.log(resp);
        return resp.ok ? resp.json() : null
      })
      .then((response) => {
        console.log("conditinal jsons",response.conditional);
        setMenuItems((response) ? response.conditional : null);
        // setTimeout(() => {
        //   window.scrollTo({ top: 0, behavior: 'smooth' })
        // });
        // settestimonalData((response&&response.length>0)?response[0].content.testimonals[0].content:null)

      })
  }
  useEffect(() => {
    updateContent();
    // window.scrollTo({top:0, behavior: 'smooth'})
  }, [])

  function divideDatas(content){
    let result=[],
    contents=[...content],
    dividePart=Math.ceil(content.length/3);
    for(var i=0;i<dividePart;i++){
      result.push({content:contents.splice(0,dividePart)})
    }
    console.log("resultant data",result);
    return result;
  }
  // console.log('blogdata', blogdata);
  return (
    <Landing footerbg={"#ffffff"} active="conditional">
      <MetaTags>
        <title>Conditions And Treatment</title>
        <meta name="keyword" content="Conditions & Treatment" />
        <meta name="description" content="Conditions & Treatment" />
      </MetaTags>
      {menuItems &&
        <div className="virtualconsulation_div testimonal_div conditional_div">
          <FixedMenu />
          <Banner className="bannerimage" bannerurl={ImageURL('blog')} >
            <div class="banner-text">
              <h3>Conditions & Treatment</h3>
            </div>
          </Banner>
          <Tabs defaultActiveKey={menuItems[0].title} id="uncontrolled-tab-example" className="testimonaltabs col4">
            {menuItems.map((obj, index) => {
              return (
                <Tab eventKey={obj.title} title={obj.title}>
                  <Container className="flexrow no-center">

                     {renderConditonal(obj)}
                    
                  </Container>
                </Tab>
              )
            })}
          </Tabs>
          <div className="mobiletabs">
            {menuItems.map((obj, index) => {
              return (
                <div className={`mobileTabsWrapper ${blogobj == obj.title && 'active'}`}>
                  <a className={`mobiletab ${blogobj == obj.title && 'active'}`} onClick={() => setblogobj(obj.title)}>{obj.title}</a>
                  {
                    blogobj == obj.title &&
                    <Container>
                      {renderConditonal(obj)}
                    </Container>
                  }

                </div>
              )
            })}
          </div>
        </div>
      }
    </Landing>
  )
}
export default ConditionsAndTreatment;