import React, { Fragment, useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, ButtonGroup, Button, ListGroup, Container, Row, Col } from 'react-bootstrap';
import Landing from '../components/Landing';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from '../components/Images';
import { NavLink, useHistory } from 'react-router-dom';
import FixedMenu from '../components/FixedMenu';
import { InformationBox, SocialIcons, ShareIcons } from '../components/HelperComponents';
import Select from 'react-select';
import { ReactSelectStyles } from '../components/reactselect';
import MetaTags from 'react-meta-tags';
import { renderBodyContent } from '../utils/extrafunctions';
const FooterPageComponent = (props) => {
	const history = useHistory();
	const [contentData, setcontentData] = useState(null);
	const isDev = process.env.REACT_APP_ENV == 'isDev';
	const isProd = process.env.REACT_APP_ENV == 'isProd';
	var blog_path = `http://localhost:3000/${props.path}`;
	// console.log(process.env);
	if (isDev) {
		blog_path = process.env.REACT_APP_PATH_DEV_FILEPATH + (props.path == 'blogs' ? process.env.REACT_APP_PATH_BLOG : process.env.REACT_APP_PATH_TESTIMONALS);
	} else if (isProd) {
		blog_path = process.env.REACT_APP_PATH_PROD_FILEPATH + (props.path == 'blogs' ? process.env.REACT_APP_PATH_BLOG : process.env.REACT_APP_PATH_TESTIMONALS);
	}
	const updateContent = (url) => {
		fetch(blog_path + url + '.json', {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then((resp) => {
				return resp.ok ? resp.json() : null
			})
			.then((response) => {
				// alert(JSON.stringify(response));
				setcontentData((response && response.length > 0) ? response[0] : null);
				setTimeout(() => {
					window.scrollTo({ top: 0, behavior: 'smooth' })
				});
			})
	}
	useEffect(() => {
		// alert(JSON.stringify(props));
		if (props.loadjson) {
			updateContent(props.loadjson)
		}
	}, [])
	// console.log("history",process.env);
	// const {title,allInfo,url} = props;
	// const [links,setlinks]=useState(null);
	// const [activelink,setactivelink]=useState(null);	
	console.log("props", props);
	const content = contentData ? contentData : props.content;
	return (
		<Landing footerbg={"#ffffff"} active={props.path}>
			<MetaTags>
				<title>{content && content.pageTitle}</title>

				{content && content.meta && content.meta.length > 0 && content.meta.map((m, i) => {
					return (
						<meta name={m.name} property={m.property} content={m.content}/>						
					)
				})}

			</MetaTags>
			<div className="virtualconsulation_div conditional_treatment_div">
				<FixedMenu direction={'right'} />
				{props.loadjson && !content && <p className="pt-2 pl-2 nocontnentred"></p>}
				{content &&
					<Fragment>
						<Banner className="bannerimage" bannerurl={ImageURL(content.content.bannerURL)}>

							<div class="banner-text">
								<h3>
									Mr. Karthik Karuppaiah's Blog
									{/*
									<span style={{cursor: "pointer"}}>
									<a className="fas fa-arrow-circle-left fa-6"
										onClick={history.goBack}>
									</a>
								</span>
								<span style={{ paddingLeft: "30px" }}>{content && content.pageTitle}</span>
						          */}
								</h3>
							</div>
						</Banner>
						{/*
<section className="info_sharebox">
<Row className="space-between pl-3 pr-3 inforow ml-0 mr-0">
<Col lg={6} md={12} className="pb-3">
<div className="flexrow">
<Images.pdf/>
<p className="pdftitle">{content.content.pdfTitle}</p>
</div>
</Col>
<Col lg={6} md={12} className="conditional_social_icons">
<div className="flexrow shareimage flex-wrap pb-3 flexend">
<Images.electronics/>
<div className="flexrow flex-wrap">
<span className="share">Share</span>
<ShareIcons link={window.location.href} override/>
</div>
</div>
</Col>
</Row>
</section>
*/}
					</Fragment>
				}
				{/*
<section className="middle_section">
<Row className="ml-0 mr-0">
<Col lg={3} md={12}>
<div className="mobilediv">
<h2>{title}</h2>
<Select onChange={(data)=>history.push(data.value)} options={links} value={activelink}/>
</div>
<ul className="all_info">
{allInfo.map((obj)=>{
	return obj.content.map((obj1)=>{
		return(
			<NavLink to={obj1.url}>{obj1.title}</NavLink>
			)
	})
})}
</ul>
</Col>
<Col lg={6} className="middle_div_col" >
{content?renderContent(content):<div className="pl-3 pr-3">No Content Found....</div>}
</Col>
<Col className="right_content text-center" lg={3} >
{content&&content.content.rightImages&&
	<Row className="ml-0 mr-0">
	{content.content.rightImages.map((images,index)=>{
		return(
<Col lg={12} md={4} xs={12} className="rightbannercol">
<Banner className="conditional_right_banner" bannerurl={ImageURL(images)}/>
</Col>
			)
	})}
</Row>
}
</Col>
</Row>
</section>*/}
				<section className="bodysection">
					{content && content.content && (content.content.quickLinks ? content.content.quickLinks : content.content.description).map((obj, index) => {
						return (
							<div id={`content${index + 1}`} className="quicklink_section">
								{Object.keys(obj).map((obj1) => {
									return renderBodyContent(obj[obj1], obj1, obj)
								})}
							</div>
						)
					})

					}
				</section>
			</div>
		</Landing>
	)
}
export default FooterPageComponent;
