import React, { Fragment, useState } from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import slider from '../images/Image 12.png';
import { CurvedLayout } from '../sytled-components/curvedlayout';
import AppointmentForm from './Forms/AppointmentForm';
import Images, { ImageURL } from './Images';
const Slider = (props) => {
  const [index, setIndex] = useState(0);
  const [toggleform, settoggleform] = useState(false);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Fragment>
      <div className="carousel_div">

        <Carousel className="slider_indicators" activeIndex={index} onSelect={handleSelect}>
          {
            props.content && props.content.map((obj, index) => {
              return (
                <Carousel.Item key={`carousel_index_${index}`}>
                  <img
                    className="d-block w-100"
                    src={ImageURL(obj.image)}
                    alt="First slide"
                  />
                  <Carousel.Caption className="slider_title">
                    <blockquote>{obj.title}</blockquote>                 
                      <cite>{obj.subtitle}</cite>                    
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })
          }
        </Carousel>
        <div className="carousel_form">
          <CurvedLayout className="curvedcard text-center" bgcolor={"white"} direction={{ left: "15px", right: 0 }}>
            <a onClick={() => settoggleform(!toggleform)}>{toggleform && <i class="fas fa-arrow-left"></i>} BOOK AN APPOINTMENT {!toggleform && <i class="fas fa-arrow-right"></i>}</a>
            <div className={`infobox ${toggleform && 'hidebox'}`}>
              {props.infos && props.infos.map((obj, index) => {
                return (
                  <div key={`info_index_${index}`} className="iconbox">
                 
                        {obj.url &&
                          <a href={obj.url} target="_blank"> <span className="customimg"><img src={ImageURL(obj.image)} alt={obj.image} /></span><span>{obj.title}</span> </a>
                        }
                                      
                        {!obj.url &&
                          <a href={obj.url} target="_blank"> <span className="customimg"><img src={ImageURL(obj.image)} alt={obj.image} /></span><span>{obj.title}</span> </a>
                        }
                   
                    </div>
                )
              })
              }
            </div>
            <div className={`appointmenttoggle ${toggleform && 'showbox'}`}>
              <AppointmentForm />
            </div>
          </CurvedLayout>
          <CurvedLayout className={`curvedcard text-center certificate ${toggleform && 'hidebox'}`} bgcolor={"white"} direction={{ left: "15px", right: 0 }}>
            {props.certificate &&
              <div className="certificatebox">
                <span className="customimg">
                  <a href="https://www.iwantgreatcare.org/doctors/mr-karthik-karuppaiah" target="_blank">
                  <img src={ImageURL(props.certificate.image)} alt={props.certificate.image} />
                  </a>
                </span>
                <p>{props.certificate.text}</p>
              </div>
            }
          </CurvedLayout>
        </div>
      </div>
    </Fragment>
  )
}
export default Slider;