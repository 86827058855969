import React from 'react';
import {Navbar,Nav,NavDropdown,Container} from 'react-bootstrap'
import Header from './Header'
import Footer from './Footer'
import '../styles/framework.scss';

function Landing(props) {
  return (
    <div>
     <Header {...props}/>
      {props.children} 
     <Footer {...props}/>
    </div>
  );
}

export default Landing;
