import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CurvedLayout, CurvedButton } from '../sytled-components/curvedlayout';
import Images, { ImageURL } from './Images';
import { NavLink } from 'react-router-dom';
const Intro = (props) => {
	console.log("props", props);
	return (
		<div className="intro">
			<Container>
				<Row>
					<Col lg={8} >
						<CurvedLayout className={"pt-4 pl-4 pr-4 leftinfo"} direction={{ left: "15px", right: 0 }} shadow>
							<div className="mobileimage">
								<img src={ImageURL(props.rightImage)}></img>
							</div>
							{props.title && <h2>{props.title}</h2>}
							{props.subtitle && <p className="intro_title">
								{props.subtitle}
							</p>}
							{props.lists &&
								<ul className="intro_specailist">
									{props.lists.map((obj, index) => {
										return (
											<li key={`intro_spec_${index}`}>{obj}</li>
										)
									})}
								</ul>
							}
							{props.description &&
								<p className="intro_desc">
									{props.description}
								</p>
							}
							{props.infoimages &&

								<div className="introclinics">
									{props.infoimages.map((infoobj, index) => {
										return (
											<a target="_blank" key={`infoobj_${index}`} href={infoobj.link}>
												<CurvedLayout className={`clinicdiv border ${infoobj.class}`} bgcolor="">
													<span className="customimg">
														<img src={ImageURL(infoobj.image)} alt={infoobj.image} />
													</span>
													</CurvedLayout>
											</a>
										)
									})}
								</div>
							}
							{props.footerlinks &&
								<div className="introFooter">
									{props.footerlinks.map((footerobj, index) => {
										return (
											<CurvedButton key={`footerobj_${index}`} className="introwidth formbtn" bgcolor={"#07447ccc"}><NavLink to={footerobj.link}><span className="customimg"><img src={ImageURL(footerobj.image)} alt={footerobj.image} /></span><span>{footerobj.title}</span></NavLink></CurvedButton>
										)
									})}
								</div>
							}
						</CurvedLayout>
					</Col>
					<Col lg={4} className="rightinfo">
						{props.rightImage && <span className="customimg"><img src={ImageURL(props.rightImage)} alt={props.rightImage} /></span>}
					</Col>
				</Row>
			</Container>
		</div>
	)
}
export default Intro;