import React, { Fragment } from 'react';
import { Carousel, Container, Row, Col, Card } from 'react-bootstrap';
import { Banner } from '../sytled-components/Banner';
import Images, { ImageURL } from './Images'
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
const datas = [1, 2, 3, 4]
const PersonalCard = (props) => {
	return (
		<Card className="personalCard">
			<Row className="peronalrow mr-0 ml-0">
				<Col md={4} xs={5} className="pl-0 pr-0">
					<Banner className="polygonimage" bannerurl={ImageURL(props.image)} />
				</Col>
				<Col className="pl-0 pr-0">
					<Card.Body className="pt-3 pl-2">
						<Card.Text className="">
							<h5>{props.title}</h5>{props.description}
						</Card.Text>
						<Row className="readmorerow ml-0 mr-0">
							<Col className="pl-0 pr-0">
								{props.showview &&
									<div className="svgicon">
										<ReactSVG src={`${ImageURL('eye')}`} />
									</div>
								}
								{props.showcomments &&
									<div className="svgicon">
										<ReactSVG src={`${ImageURL('eye')}`} />
									</div>
								}
							</Col>
							<Col className="text-right pl-0 pr-0">
								<NavLink to={`blog/${props.read_more_link}`}>{props.read_more}</NavLink>
							</Col>
						</Row>
					</Card.Body>
				</Col>
			</Row>
		</Card>
	)
}
const PersonBlog = (props) => {
	return (
		<div className="personalblogdiv">
			<Container>
				{props.title &&
					<h2>{props.title}</h2>
				}
				<Row>
					{props.content.sort((b, a) => a.createdAt - b.createdAt).slice(0, 4).map((obj) => {
						return (
							<Col className="flexcol-blog" lg={6} md={12} xs={12} >
								<PersonalCard {...obj} />
							</Col>
						)
					})}
				</Row>
				<div className="viewmore">
					<NavLink to={props.view_more_link}>{props.view_more}</NavLink>
				</div>
			</Container>
		</div>
	)
}
export default PersonBlog;