import React, { Fragment } from 'react';
import mail from '../assets/images/mail.svg';
import mailBlue from '../assets/images/mail-blue.svg';
import smartphone from '../assets/images/smartphone.svg';
import smartphoneBlue from '../assets/images/smartphone-blue.svg';
import certificate from '../assets/images/certificate.png';
import karthik_karuppaiah from '../assets/images/karthik_karuppaiah_01.png';
import medal from '../assets/images/medal.svg';
import user from '../assets/images/user.svg';
import research from '../assets/images/research.svg';
import bess from '../assets/images/bess.jpg';
import westkent from '../assets/images/westkent.png';
import bios from '../assets/images/bios.png';
import tnorp from '../assets/images/tnorp.png';
import bssh from '../assets/images/bssh.png';
import bone from '../assets/images/bone.png';
import bone1 from '../assets/images/bone1.png';
import eye from '../assets/images/eye.svg';
import altimage from '../assets/images/altimage.jpg';
import bone3 from '../assets/images/bone3.png';
import virtual from '../assets/images/virtual.png';
import contactus from '../assets/images/contactus.png';
import lbhospital from '../assets/images/lbhospital.png';
import kingshospital from '../assets/images/kingshospital.png';
import chelshospital from '../assets/images/chelshospital.png';
import bmihospital from '../assets/images/bmihospital.png';
import testimonial from '../assets/images/testimonal.png';
import media from '../assets/images/media.png';
import blog from '../assets/images/blog.png';
import shoulder_l from '../assets/images/shoulder_l.png';
import pdf from '../assets/images/pdf.svg';
import pdf_download from '../assets/images/pdf_download.png';
import pdf_print from '../assets/images/pdf_print.png';
import electronics from '../assets/images/electronics.svg';
import xrayshoulder from '../assets/images/xrayshoulder.png';
import shoulderbone from '../assets/images/shoulderbone.png';
import backsh from '../assets/images/backsh.png';
import xray1 from '../assets/images/xray1.png';
import xray2 from '../assets/images/xray2.png';
import handxray from '../assets/images/handxray.png';
import calendar from '../assets/images/calendar.png';
import lips from '../assets/images/lips.png';
import bonejoint from '../assets/images/bonejoint.png';
import message from '../assets/images/message.png';
import jcie from '../assets/images/jcie.png';
import church from '../assets/images/church.jpg';
import kings from '../assets/images/kings.png';
import healix from '../assets/images/healix.png';
import wpa from '../assets/images/wpa.png';
import exeter from '../assets/images/exeter.png';
import allianz from '../assets/images/allianz.png';
import health from '../assets/images/health.png';
import AXA from '../assets/images/AXA.png';
import Bupa from '../assets/images/Bupa.png';
import Vitality from '../assets/images/Vitality.png';
import Cigna from '../assets/images/Cigna.png';
import Aviva from '../assets/images/Aviva.png';
import handbanner from '../assets/images/hand_banner.png';
import image12 from '../assets/images/Image 12.png';
import Logo from '../assets/images/Logo.png';
import Favcon from '../assets/images/Favcon.png';
import author_karuppaiah from '../assets/images/author_karthik_karuppaiah.jpg';
import common_avatar from '../assets/images/avatar-icon.png';
import testimonial_slide from '../assets/images/testimonial_slide.jpg';
import banner_bg from '../assets/images/banner_bg.png';

import new_BESS from '../assets/images/membership/BESS.jpg';
import new_BIOS from '../assets/images/membership/BIOS.jpg';
import new_BSSH from '../assets/images/membership/BSSH.jpg';
import new_JCIE from '../assets/images/membership/JCIE.jpg';
import new_KCL from '../assets/images/membership/KCL.jpg';
import new_RCS from '../assets/images/membership/RCS.jpg';
import new_TIOA from '../assets/images/membership/TIOA.jpg';
import new_TOA from '../assets/images/membership/TOA.jpg';
import new_WKMCS from '../assets/images/membership/WKMCS.jpg';


const arrayofimages = {
	mail: mail, 'smartphone': smartphone, 'certificate': certificate, 'karthik_karuppaiah': karthik_karuppaiah, 'medal': medal, 'user': user, 'research': research, 'bess': bess, 'westkent': westkent, bios: bios, tnorp: tnorp, bssh: bssh, eye: eye, bone: bone, bone1: bone1, altimage: altimage, bone3: bone3, virtual: virtual, contactus: contactus, lbhospital: lbhospital, kingshospital: kingshospital, chelshospital: chelshospital, bmihospital: bmihospital, testimonial: testimonial, media: media, blog: blog, shoulder_l: shoulder_l, pdf: pdf, electronics: electronics, xrayshoulder: xrayshoulder, shoulderbone: shoulderbone, backsh: backsh, xray1: xray1, xray2: xray2, handxray: handxray, calendar: calendar, lips: lips, bonejoint: bonejoint, message: message, jcie: jcie, church: church, kings: kings, healix: healix, wpa: wpa, exeter: exeter, allianz: allianz, health: health, AXA: AXA, Bupa: Bupa, Vitality: Vitality, Cigna: Cigna, Aviva: Aviva, handbanner: handbanner, image12: image12, Logo: Logo, Favcon: Favcon, author_karuppaiah: author_karuppaiah
	, common_avatar: common_avatar, testimonial_slide: testimonial_slide, banner_bg: banner_bg,mailBlue:mailBlue, smartphoneBlue: smartphoneBlue
	, new_BESS: new_BESS, new_BIOS: new_BIOS, new_BSSH: new_BSSH, new_JCIE: new_JCIE, new_KCL: new_KCL, new_RCS: new_RCS, new_TIOA: new_TIOA, new_TOA: new_TOA, new_WKMCS: new_WKMCS, pdf_print:pdf_print, pdf_download:pdf_download
};
const ImageRendering = (props) => {
	return (
		<span className="customimg"><img width={props.width} height={props.height} src={props.image} alt={props.imagetitle} /></span>
	)
}
const Images = ({ children }) => <Fragment>{children}</Fragment>;
Object.keys(arrayofimages).map((obj) => {
	Images[obj] = (props) => <ImageRendering {...props} image={arrayofimages[obj]} imagetitle={obj} />
})
export const ImageURL = (img) => {
	if (img.includes("/")) {
		return img;
	} else {
		return arrayofimages[img];
	}

}

export default Images;