import React from 'react';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';
import Slider from "react-slick";
import Images, { ImageURL } from './Images';
import { Banner } from '../sytled-components/Banner';
const Customerslider = (props) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 4000,  
    adaptiveHeight: true,
    variableWidth: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear', 
    slidesToShow: 1, 
    slidesToScroll: 1, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          variableWidth: true,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          variableWidth: true,
          infinite: true,
          dots: false
        }
      }]
  };
  return (
    <div className="customerslidermain">
      <Container>
        {props.customer_imgs &&
          <Slider {...settings} className="customerslider">
            {props.customer_imgs.map((obj, index) => {
              return (
                <div>
                  <a href={obj.link} target="_blank"><img src={ImageURL(obj.img)} /></a>
                </div>

              )
            })}

          </Slider>
        }
      </Container>
    </div>
  )
}
export default Customerslider;